export default function userReducer(userState, action) {
  switch (action.type) {
    case 'login':
      userState = true
      return userState
    case 'logout':
      userState = false
      return userState
    default:
      return userState
  }
}