import React from 'react';
import { FiltreWrap } from './styles'
export default (props) => {
    return <FiltreWrap>
        <select value={props.selectValue} onChange={(e) => props.change(e.target.value)}>
            <option value="false">Filtre Tipi</option>
            <option value="round">Round</option>
            <option value="hotel">Hotel</option>
            <option value="paketname">Paket</option>
        </select>
        <input
            value={props.textValue}
            onChange={(e) => props.changeText(e.target.value)}
            type="textbox" />
    </FiltreWrap>
}