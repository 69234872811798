import styled from 'styled-components';

export const Wrapper = styled.div`
flex-basis:100%;
display:flex;
flex-direction:column;
`
export const UL = styled.ul`
display:flex;
flex-basis:100%;
flex-direction:row;
margin:0;
padding:0;
`
export const Li = styled.li`
padding:10px;
box-sizing:border-box;
list-style:none;
flex-basis:20%;
display:flex;
flex-direction:column;
justify-content: space-between;
align-items:center;
img{
    width:100%;
    object-fit: cover;
    height: 150px;
    border-radius:4px;
    margin-bottom:10px;
}
span{
    display:flex;
    flex-direction:column;
    align-items:center;
    button{
        margin:10px;
    }
}
`