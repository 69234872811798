import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../styles/react-draft-wysiwyg.css'
import '../../styles/Draft.css'
import { toolbarDefine } from '../../utils/editorToolbar'
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr } from './editorInitial/';
function WYSIWYG(props) {
  const [editorState, setEditorState] = useState(initialStateRu);
  const [defaultState, setDefaultState] = useState(props.value);
  let deger;
  function _stateConverter(data) {
    const { contentBlocks, entityMap } = htmlToDraft(data || '');
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  }
  // useEffect(() => {
  //   const convertState = _stateConverter(props.value);
  //   setEditorState(convertState)
  // }, [])
  useEffect(() => {
    console.log('component did update');
    console.log(props.value);
    const convertState = _stateConverter(props.value);
    setEditorState(convertState)
    return () => { 
      // deger = convertState

    }
  },[props.value]);

  function onEditorStateChange(data) {
    setEditorState(data);
    console.log("PROPS ==> ", data);
    const html = draftToHtml(convertToRaw(data.getCurrentContent()))
    props.onChange(html);
  };
  return (
    <Editor
      // defaultEditorState={editorState}
      editorState={editorState}
      onEditorStateChange={(e) => onEditorStateChange(e)}
      toolbar={toolbarDefine}
    />
  );
}

export default WYSIWYG;