import styled from 'styled-components'

export const Wrapper = styled.div`
box-sizing:border-box;
display:flex;
width:100%;
flex-direction:row;
`
export const List = styled.div`
overflow:auto;
flex-basis:50%;
ul{
    padding:0;
    margin:0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    li{
        list-style:none;
        padding:5px;
        }
}
`
export const Inputs = styled.div`
padding:0px 50px;
flex-basis:50%;
box-sizing:border-box;
display:flex;
flex-direction:column;
label{
  margin-top:10px;
  float:left;
  font-size:12px; 
  input{
    width:100%;
    padding:15px;
    box-sizing:border-box;
}
}

button{
  float:left;
  margin-top:10px;
  padding:15px 30px;
  box-sizing:border-box;
  border:none;
  border-radius:4px;
  background-color:#ff9229;
  color:white;
  text-transform:uppercase;
  font-weight:bold;
  transition:300ms;
  cursor:pointer;
  &:hover{
    background-color:#06a287;
    transition:300ms;
  }
}
`