import React from 'react';
import { IoIosAdd } from "react-icons/io";
import { ButtonSC, Listviewer, ListviewerTop, ListviewerUl, ButtonWrapper } from './styles'
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
const ListeView = (props) => {
    const { liste, clickEvent, clickDel, title } = props;
    return (
        <Listviewer>
            <ListviewerTop>
            <h3>{title}</h3>
            {/* <div className="aciklayici">
            <span> Üst Menü</span>
            <span> Alt Menü</span>
            </div> */}
            <ButtonSC name="ekle" onClick={(e) => props.ekle(e)}>
                <IoIosAdd /> Ekle</ButtonSC>
                </ListviewerTop>
            {liste ?  <ListviewerUl>
                {liste.map(function (x, i) {

                    return <li key={i}>
                        <span>{x.name}</span>
                        <ButtonWrapper>
                        <ButtonSC onClick={() => clickDel(x)}>
                            <MdDeleteForever />
                        </ButtonSC>
                        <ButtonSC onClick={() => clickEvent(x)}>
                            <MdModeEdit />
                        </ButtonSC>
                        </ButtonWrapper>
                    </li>

                })}
            </ListviewerUl>
                : "Veri Yok"}

        </Listviewer>
    )
}
export default ListeView;
