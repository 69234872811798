import React, { useState, useEffect, useContext } from 'react';
import { _langlist } from '../services'
import { LangContext } from '../../../stores/langContext';
function Baslik(props) {
    return props.list ? props.list.map((x, i) => {
        // let value = x.value,
        //     namedata = x.code,
        //     // disabled = x.active ? '' : 'disabled',
        //     place = x.name,
        //     index = i;

        // props.data && props.data.map((y, z) => {
        //     if (x.code == y.language_code) {

        //         // disabled = x.active ? 'disabled ' : '';
        //         value = y.value;
        //         namedata = y.language_code;
        //         // index = z || i

        //     }
        // })
        return <input
            key={i}
            type="text"
            required
            value={x.value || ""}
            // disabled={disabled}
            name={x.name}
            placeholder={`Dil ${x.name}`}
            onChange={(e) => props.change(e, i)} />
    }) : false

}

export default Baslik;