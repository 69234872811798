import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom'
import {
    Button, TextField, Switch, FormGroup, FormLabel, FormControlLabel, Box, FormControl
    , InputLabel
} from '@mui/material';
// import { FormWrapperSc } from './styles'
import { useHistory } from "react-router-dom";
import { _analyticslistesi, _analyticsDetay, _analyticsGuncelle, _analyticsKaydet, _analyticsSil } from './services'
function AddEdit() {
    // const { id } = match.params;
    let history = useHistory();
    let { id } = useParams();
    const isAddMode = !id;
    const [user, setUser] = useState({});
    const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    function onSubmit(data) {
        return isAddMode
            ? createUser(data)
            : updateUser(id, data);
    }

    function createUser(data) {
        return _analyticsKaydet(data)
            .then(() => {
                toast.success('Ekleme başarılı.')
                history.push('.');
            })
            .catch((err) => {
                toast.error('Ekleme başarısız.')
                console.log(err)
            });
    }

    function updateUser(id, data) {
        return _analyticsGuncelle(data, id)
            .then(() => {
                toast.success('Güncelleme başarılı.')
                history.push('..');
            })
            .catch((err) => {
                toast.error('Güncelleme başarısız.')
                console.log(err)
            });
    }
    useEffect(() => {
        if (!isAddMode) {
            _analyticsDetay(id).then(user => {
                const fields = ['code', 'name', 'active'];
                fields.forEach(field => setValue(field, user.data.data[field]));
                setUser(user);
            });
        }
    }, []);
    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <FormLabel>Dil {!isAddMode ? "Düzenle" : "Ekle"}</FormLabel>
            <FormGroup column>
                <Controller
                    render={({ field }) => <TextField {...field} label="Kod" margin="normal" variant="outlined" />}
                    control={control}
                    name="code"
                    defaultValue=""
                />
                <Controller
                    render={({ field }) => <TextField {...field} label="Görünen İsim" margin="normal" variant="outlined" />}
                    control={control}
                    name="name"
                    defaultValue=""
                />
                <FormGroup>
                    <FormLabel component="legend">Durum</FormLabel>
                    <Controller
                        name="active"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                />}
                                label={field.value?'Aktif':'Pasif'}
                            />

                        )}
                    />
                </FormGroup>
            </FormGroup>


            <FormGroup row >
                <Box m={1} ml={0}>
                    <Button variant="contained" color="primary" type="submit">
                        Kaydet
                    </Button>
                </Box>
                <Box m={1}>
                    <Button component={Link} variant="contained" color="secondary" to={isAddMode ? '.' : '..'}>
                        İptal
                    </Button>
                </Box>
            </FormGroup>
            {errors.code && <span>This field is required</span>}
        </Box >
    );
}
export { AddEdit }