import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
export const _images = (id) => {
    return axios.get(API_URL + `/image/gallery/${id}`, { headers: authHeader() })
}
export const _silResim = (id) => {
    return axios.delete(API_URL + `/image/${id}`, { headers: authHeader() })
}
export const _multisliderlistesi = () => {
    return axios.get(API_URL + '/multislider', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _multisliderDetay = (id) => {
    return axios.get(API_URL + `/multislider/${id}`, { headers: authHeader() })
}
export const _multisliderSil = (id) => {
    return axios.delete(API_URL + `/multislider/${id}`, { headers: authHeader() })
}
export const _multisliderKaydet = (data) => {
    const params = {
        baslik_tr: data.baslik_tr,
        baslik_en: data.baslik_en,
        baslik_ru: data.baslik_ru,
        baslik_de: data.baslik_de,
        baslik_cz: data.baslik_cz,
        aciklama_tr: data.aciklama_tr,
        aciklama_en: data.aciklama_en,
        aciklama_ru: data.aciklama_ru,
        aciklama_de: data.aciklama_de,
        aciklama_cz: data.aciklama_cz,
        image: data.image,
        link: data.link,
    }
    return axios.post(API_URL + `/multislider`, { ...params }, { headers: authHeader() })
}
export const _upload = (data, cb) => {
    return axios.post(API_URL + `/upload`, data, { headers: authHeader() })
}
export const _multisliderGuncelle = (data, id) => {
    return axios.put(API_URL + `/multislider/${id}`, { ...data }, { headers: authHeader() })
}
