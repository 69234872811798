import React from 'react';
import { useHistory } from "react-router-dom"
import modules from '../../modules';

import clsx from 'clsx';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import { List } from '@mui/material';
import { ListItem } from '@mui/material';
import { ListItemText } from '@mui/material';
import { ListSubheader } from '@mui/material';
import { Divider } from '@mui/material';
import { Collapse } from '@mui/material';

import { ListItemIcon, Box, ListItemButton } from '@mui/material/';
import { IconButton } from '@mui/material';
import { ChevronLeft, Inbox, ExpandLess, ExpandMore, AltRoute, BurstMode } from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
export default function Drawe(props) {
    let history = useHistory();
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [openCollapse1, setCollapse1] = React.useState(false);
    const [openCollapse2, setCollapse2] = React.useState(false);
    const [openCollapse3, setCollapse3] = React.useState(false);
    function git(go, selected) {
        setSelectedIndex(selected);
        history.push(`${go}`);

    }
    function handleClick1() {
        setCollapse1(!openCollapse1);
    };
    function handleClick2() {
        setCollapse2(!openCollapse2);
    };
    function handleClick3() {
        setCollapse3(!openCollapse3);
    };
    return (
        <Drawer variant="permanent" open={props.open}>
            <DrawerHeader>
                <IconButton onClick={() => props.close()}>
                    <ChevronLeft />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {modules.map((module, i) => (
                    (!module.headerLocale && !module.hideMenu && module.listCategory == 2) &&
                    // <Box border={1}>
                    <ListItem
                        button
                        onClick={() => git(module.routeProps.path, module.name)}
                        key={module.name}
                        selected={selectedIndex === module.name}
                    >
                        <ListItemIcon>
                            {module.icon}
                        </ListItemIcon>
                        <ListItemText primary={module.name} />
                    </ListItem>
                    // </Box>
                ))}
                <ListItemButton onClick={handleClick1}>
                    <ListItemIcon>
                        <AppRegistrationIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tanımlar" />
                    {openCollapse1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCollapse1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {modules.map((module, i) => (
                            module.listCategory == 1 &&
                            <ListItemButton sx={{ pl: 4 }}
                                button
                                onClick={() => git(module.routeProps.path, module.name)}
                                key={module.name}
                                selected={selectedIndex === module.name}
                            >
                                <ListItemIcon>
                                    {module.icon}
                                </ListItemIcon>
                                <ListItemText primary={module.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
                <ListItemButton onClick={handleClick2}>
                    <ListItemIcon>
                        <BurstMode />
                    </ListItemIcon>
                    <ListItemText primary="Görsel Merkezi" />
                    {openCollapse2 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCollapse2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {modules.map((module, i) => (
                            module.listCategory == 3 &&
                            <ListItemButton sx={{ pl: 4 }}
                                button
                                onClick={() => git(module.routeProps.path, module.name)}
                                key={module.name}
                                selected={selectedIndex === module.name}
                            >
                                <ListItemIcon>
                                    {module.icon}
                                </ListItemIcon>
                                <ListItemText primary={module.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </List>
            <Divider />
            <List>
                {/* <ListSubheader inset>Yönetimsel Araçlar </ListSubheader> */}
                <ListItemButton onClick={handleClick3}>
                    <ListItemIcon>
                        <AltRoute />
                    </ListItemIcon>
                    <ListItemText primary="Dış Kaynaklar" />
                    {openCollapse3 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openCollapse3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon>
                            <ListItemText primary="Starred" />
                        </ListItemButton> */}
                        {modules.map((module, i) => (
                            module.manage &&
                            <ListItemButton sx={{ pl: 4 }}
                                button
                                onClick={() => git(module.routeProps.path, module.name)}
                                key={module.name}
                                selected={selectedIndex === module.name}
                            >
                                <ListItemIcon>
                                    {module.icon}
                                </ListItemIcon>
                                <ListItemText primary={module.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </List>
        </Drawer>
    );
}

