import React, { useState, useEffect } from 'react';
import Modal from "../../components/Modal";
import Lister from '../../components/List/'
import { FaQuestionCircle, FaImage } from 'react-icons/fa';
import { IoIosAdd, IoMdRefreshCircle, IoMdSave } from "react-icons/io";
import Multistep from 'react-multistep';
import { ToastContainer, toast } from 'react-toastify';
import createFileList from 'create-file-list'
import 'react-toastify/dist/ReactToastify.css';
import Switcher from "react-switch";
import ProgressBar from '../../components/progressBar'

import GalleryItem from './galleryItem'
import BurstModeIcon from '@mui/icons-material/BurstMode';
import GalleryItemNew from './galleryItemNew'
import { _galleryGuncelle, _silResim, _galleryDetay, _gallerySil, _upload, _gallerylistesi, _galleryKaydet, _hotellistesi, _sahalistesi } from './services'
function Gallery(props) {
    const [data, setData] = useState(false);
    const [secim, setSecim] = useState(false);
    const [dataBaslik, setDataBaslik] = useState(false);
    const [liste, setListe] = useState(false);
    const [hotellist, setHotellist] = useState(false);
    const [sahalist, setSahalist] = useState(false);
    const [checked, setChecked] = useState(false);
    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState("0");
    function handleChangeSwitcher(value) {
        setChecked(value);
    }
    function showModal() {
        setShow(!show);
    };
    function showModalAndClear(e) {
        setSecim(false);
        setShow(!show);
    };
    function getir() {
        _gallerylistesi()
            .then(list => {
                setDataBaslik(list.data.data);
            });
        _hotellistesi().then(list => {
            setHotellist(list.data.data);
        });
        _sahalistesi().then(list => {
            setSahalist(list.data.data);
        });
    }
    useEffect(() => {
        _gallerylistesi()
            .then(list => {
                setDataBaslik(list.data.data);
            });
        _hotellistesi().then(list => {
            setHotellist(list.data.data);
        });
        _sahalistesi().then(list => {
            setSahalist(list.data.data);
        });
    }, []);

    function _contentUpdateSliderItem() {
        const { id, name, hotel_id, saha_id } = secim;
        _galleryGuncelle({
            name: name,
            checked: checked,
            outid: checked ? secim.hotel_id : secim.saha_id,
        }, id)
            .then(function (data) {
                if (!data.data.error) {
                    const formData = new FormData();
                    if (secim.storefile) {
                        for (let i = 0; i < secim.storefile.length; i++) {
                            formData.append(`photos`, secim.storefile[i])
                            if (secim.storefile[i]) {
                                console.log("girdi")
                                formData.append(`setCover[]`, JSON.stringify({ name: secim.storefile[i].name, setCover: secim.storefile[i].setCover, sira_no: secim.storefile[i].sira_no }))
                            }
                        }
                        formData.append(
                            "id",
                            id
                        );
                        _upload(formData, setProgress)
                            // axios.post(props.apiUrl + '/uploads', formData, config)
                            .then(res => {
                                console.log(res);
                                // setProgress(progress);
                                setShow(false);
                                toast.success('Tüm resimler aktarıldı.')
                            })
                            .catch(err => console.log(err))
                    }
                    getir();
                }
            });

    }
    function _contentUpdateSliderItemClear() {
        setSecim(false);
    }

    function _handleSec(e) {
        let secimProp = { ...secim }
        let alan = `${e.target.name}`;
        if (alan === "storefile") {
            secimProp[alan] = e.target.files;
            setSecim(secimProp)
        } else {
            secimProp[alan] = e.target.value;
            setSecim(secimProp)
        }
    }
    function _clickEvent(e) {
        _galleryDetay(e.id)
            .then((gelen) => {
                console.log(gelen)
                setSecim(gelen.data.data)
                setChecked(gelen.data.data.checked)
                showModal(e);
            });
    }
    function _contentSave() {
        console.log(secim)
        _galleryKaydet({
            name: secim.name,
            checked: checked,
            outid: checked ? secim.hotel_id : secim.saha_id,
        })
            .then(function (res) {
                console.log(res)
                const formData = new FormData();
                for (let i = 0; i < secim.storefile.length; i++) {
                    formData.append(`photos`, secim.storefile[i])
                    if (secim.storefile[i]) {
                        console.log("girdi")
                        formData.append(`setCover[]`, JSON.stringify({ name: secim.storefile[i].name, setCover: secim.storefile[i].setCover, sira_no: secim.storefile[i].sira_no }))
                    }
                }
                formData.append(
                    "id",
                    res.data.data
                );
                const config = {
                    onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        setProgress(percentCompleted)
                    }
                }
                console.log("formData")
                const sendData = formData.getAll('setCover')
                console.log(sendData)
                for (let [key, value] of formData.entries()) {
                    console.log(key, ':', value);
                }
                _upload(formData, setProgress)
                    // axios.post(props.apiUrl + '/uploads', formData, config)
                    .then(res => {
                        console.log(res);
                        // setProgress(progress);
                        setShow(false);
                        toast.success('Tüm resimler aktarıldı.')
                    })
                    .catch(err => console.log(err))
                if (!res.data.error) {
                    getir();
                    toast.success('Galeri Oluşturuldu')
                } else {
                    toast.error('Galeri Oluşturmada hata')
                }
            })



    }
    function _clickDel(x) {
        _gallerySil(x)
            .then(response => getir())
    }
    function _resimSil(x) {
        _silResim(x).then(res => {
            getir()
            // _clickEvent(secim.id)
        })
    }
    function _resimSilYeni(x) {
        console.log(x)
        console.log(secim.storefile)
        const za = secim.storefile;
        console.log(za)
        const key = x;

        const arr = secim.storefile;
        const { [key]: foo, ...rest } = arr;
        console.log(rest)

        const liste = createFileList(Object.keys(rest).map(function (key, index) {


            return rest[key];
        }))
        console.log(rest)
        setSecim({ ...secim, storefile: liste })
    }
    function setCover(x) {
        const files = secim.storefile;
        const liste = createFileList(Object.keys(files).map(function (key, index) {
            if (x === key) {

                Object.defineProperty(files[key], 'setCover', {
                    value: files[key].setCover ? !true : !false,
                    writable: true
                });

            }

            return files[key];
        }))

        setSecim({ ...secim, storefile: liste })

    }
    function setSira(x, val) {
        console.log(val)
        const files = secim.storefile;
        const liste = createFileList(Object.keys(files).map(function (key, index) {
            if (x === key) {

                Object.defineProperty(files[key], 'sira_no', {
                    value: val,
                    writable: true
                });

            }

            return files[key];
        }))

        setSecim({ ...secim, storefile: liste })

    }
    const steps = [
        {
            name: 'Galeri Adı', component: <div className="inputs">
                <div className="editorName">
                    <input type="textbox" placeholder="Sağlayıcı Adı Örn:WhatsApp" name="name" value={secim.name || ""} onChange={(e) => _handleSec(e)} />

                </div>
            </div>
        },
        {
            name: 'Türü', component: <div className="inputs"><div className="editorName">
                <span>Saha</span>
                {//:TODO: Switcher dışardan bi library değil kendi yazdığım bi komponent olmalı
                }
                <Switcher
                    onChange={handleChangeSwitcher}
                    checked={checked}
                    id="normal-switch"
                />
                <span>Hotel</span>
                {checked ? <div className="checktasiyici">
                    <label>Hotel Seç</label>
                    <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
                    {/* <input type="checkbox" required value={secim.single_price || ""} name="single_price" placeholder="e.g:10000" onChange={(e) => this._inputChange(e)} /> */}
                    <select
                        onChange={(e) => _handleSec(e)}
                        value={Array.isArray(secim.hotels) && secim.hotels.length ? secim.hotels[0].id : secim.hotel_id} name="hotel_id">
                        <option>"Seçilmedi"</option>
                        {hotellist ? hotellist.map((x, i) => {
                            return <option key={i} value={x.id}>{x.name}</option>
                        }) : false}
                    </select>

                </div> : <div className="checktasiyici">
                        <label>Saha Seç</label>
                        <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
                        {/* <input type="checkbox" required value={secim.single_price || ""} name="single_price" placeholder="e.g:10000" onChange={(e) => this._inputChange(e)} /> */}
                        <select onChange={(e) => _handleSec(e)}
                            value={Array.isArray(secim.saha) && secim.saha.length ? secim.saha[0].id : secim.saha_id} name="saha_id">
                            <option>"Seçilmedi"</option>
                            {sahalist ? sahalist.map((x, i) => {
                                return <option key={i} value={x.id}>{x.name}</option>
                            }) : false}
                        </select>
                    </div>}


            </div></div>
        },
        {
            name: 'Yükleme', component: <div className="inputs">
                <GalleryItem
                    silResim={_resimSil}
                    gallery_id={secim.id}
                    data={secim.image}
                    setSira={setSira}
                />
                <GalleryItemNew
                    setCover={setCover}
                    setSira={setSira}
                    silResim={_resimSilYeni}
                    data={secim.storefile} />
                <div className="editorName">
                    <input
                        type="file"
                        placeholder="Sağlayıcı Adı Örn:WhatsApp"
                        name="storefile"
                        files={secim.storefile}
                        // value={secim.storefile && secim.storefile}
                        multiple={true}
                        onChange={(e) => _handleSec(e)} />
                </div>
            </div>
        },
        {
            name: 'Bitir', component: <div className="editorName">
                {progress === "0" ? false : <ProgressBar done={progress} />}
                <button disabled={(secim && !secim.id) ? false : true} onClick={_contentSave}><IoMdSave />Kaydet</button>
                <button disabled={secim.id ? false : true} onClick={_contentUpdateSliderItem}><IoMdRefreshCircle />Güncelle</button>
                <button disabled={secim ? false : true} onClick={_contentUpdateSliderItemClear}> <IoIosAdd />Yeni Oluştur</button>
            </div>
        }
    ]

    return <div className="tasiyici">
        <ToastContainer />
        <Modal onClose={showModal} show={show}>
            <Multistep showNavigation={true} steps={steps} />
        </Modal>
        <div className="sol">
            <div className="liste">
                {dataBaslik ? <Lister
                    filter={""}
                    listeBaslik={"Galeri"}
                    dataBaslik={dataBaslik}
                    showModalAndClear={showModalAndClear}
                    _clickDel={_clickDel}
                    _clickEvent={_clickEvent}
                    visual={"name"}
                /> : "Yükleniyor."}
            </div>
        </div>

    </div>
}


export default {
    routeProps: {
        path: '/gallery',
        component: Gallery,
    },
    name: 'Gallery',
    icon: <BurstModeIcon fontSize="small"/>,
    hideMenu: false,
    listCategory:3
};