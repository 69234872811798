import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
const service = {
    sort: () => {
        return axios.get(API_URL + '/platforms', { headers: authHeader() })
    },
    detail: (id) => {
        return axios.get(API_URL + `/platforms/${id}`, { headers: authHeader() })
    },
    delete: (id) => {
        return axios.delete(API_URL + `/platforms/${id}`, { headers: authHeader() })
    },
    save: (data) => {
        const params = {
            name: data.name,
            trackedvalue: data.trackedvalue
        }
        return axios.post(API_URL + `/platforms`, { ...params }, { headers: authHeader() })
    },
    update: (data, id) => {
        return axios.put(API_URL + `/platforms/${id}`, { ...data }, { headers: authHeader() })
    }
}
export default service
// export const _platformlistesi = () => {
//     return axios.get(API_URL + '/platforms', { headers: authHeader() })
// }
// export const _platformDetay = (id) => {
//     return axios.get(API_URL + `/platforms/${id}`, { headers: authHeader() })
// }
// export const _platformSil = (id) => {
//     return axios.delete(API_URL + `/platforms/${id}`, { headers: authHeader() })
// }
// export const _platformKaydet = (data) => {
//     const params = {
//         name: data.name,
//         trackedvalue: data.trackedvalue
//     }
//     return axios.post(API_URL + `/platforms`, { ...params }, { headers: authHeader() })
// }
// export const _paketdenSahaSil = (id) => {
//     return axios.delete(API_URL + `/saha_and_paket/${id}`, { headers: authHeader() })
// }
// export const _paketdenPriceSil = (id) => {
//     return axios.delete(API_URL + `/prices/${id}`, { headers: authHeader() })
// }
// export const _paketdenIcerikSil = (id) => {
//     return axios.delete(API_URL + `/icerik/${id}`, { headers: authHeader() })
// }
// export const _platformGuncelle = (data, id) => {
//     return axios.put(API_URL + `/platforms/${id}`, { ...data }, { headers: authHeader() })
// }