import React from 'react';
import PropTypes from 'prop-types';
import List from './list'
import { DevicesOther } from '@mui/icons-material';
import { Route, Switch } from 'react-router-dom';
import { AddEdit } from './AddEdit'
import { Grid, Box } from '@mui/material'
const Platforms = function (props) {
    return <Switch>
        <Route exact path={`${props.path}`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={3} xl={3}>
                    <List
                        baslik={"Platformlar"}
                        path={props.path}
                    />
                </Grid>
                <Grid item sm={12} md={9} xl={9}>
                    <Box p={5}>
                        <p>Sitedeki ziyaretçileri mesajlaşmayı sağlıcak uygulamaları yönetebildiğniz kısım.
          <i>"Ekle"</i> butonu ile yeni bir platform ekleyebilirsiniz.</p>
                        <h5>Örneğin:</h5>
                        <ul>
                            <li>Whatsapp</li>
                            <li>Hangouts</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/add`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/edit/:id`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
    </Switch >
    // const [secim, setSecim] = useState(false);
    // const [dataBaslik, setDataBaslik] = useState(false);
    // const [show, setShow] = useState(false);
    // useEffect(() => {
    //     service.sort()
    //         .then(list => {
    //             setDataBaslik(list.data.data)
    //         })
    // }, [props.apiUrl])
    // function showModal(e) {
    //     setShow(!show)
    // };
    // function showModalAndClear(e) {
    //     setShow(!show)
    //     setSecim(false)
    // };
    // function getir() {
    //     service.sort()
    //         .then(list => {
    //             setDataBaslik(list.data.data)
    //         })
    // }
    // function _contentUpdateSliderItem() {

    //     const { id, name, trackedvalue } = secim;
    //     service.update()({ name, trackedvalue }, id)
    //         .then(function (res) {
    //             if (!res.data.error) {
    //                 toast.success('Güncelleme başarılı.')
    //                 getir();
    //             } else {
    //                 toast.error('Güncelleme yapılamadı.')
    //             }
    //         });

    // }
    // function _contentUpdateSliderItemClear() {
    //     setSecim(false)
    // }
    // function _handleSec(e) {
    //     let secimProp = { ...secim }
    //     let alan = `${e.target.name}`;
    //     secimProp[alan] = e.target.value;
    //     setSecim(secimProp)
    // }
    // function _clickEvent(e) {
    //     service.detail(e.id)
    //         .then((gelen) => {
    //             setSecim(gelen.data.data)
    //             showModal(e);
    //         });
    // }
    // function _contentSave() {
    //     service.save({
    //         name: secim.name,
    //         trackedvalue: secim.trackedvalue,
    //     })
    //         .then(function (res) {
    //             if (!res.data.error) {
    //                 toast.success('Kaydetme başarılı.')
    //                 getir();
    //             } else {
    //                 toast.error('Kaydetme yapılamadı.')
    //             }
    //         });


    // }
    // function _clickDel(x) {
    //     service.delete(x)
    //         .then(function (res) {
    //             if (!res.data.error) {
    //                 toast.success('Silme başarılı.')
    //                 getir();
    //             } else {
    //                 toast.error('Silme yapılamadı.')
    //             }
    //         });
    // }
    // return <div className="tasiyici">
    //     <Modal baslik={"Platform Ekle / Düzenle"} onClose={showModal} show={show}>
    //         <div className="inputs">
    //             <div className="editorName">
    //                 <input type="textbox" placeholder="Sağlayıcı Adı Örn:Google" name="name" value={secim.name || ""} onChange={(e) => _handleSec(e)} />
    //                 <input type="textbox" placeholder="Takip Kodu Örn:UA-00000000-00" name="trackedvalue" value={secim.trackedvalue || ""} onChange={(e) => _handleSec(e)} />
    //             </div>
    //             <div className="editorName">
    //                 <button className="buttons" disabled={(secim && !secim.id) ? false : true} onClick={() => _contentSave()}><IoMdSave />Kaydet</button>
    //                 <button className="buttons" disabled={secim.id ? false : true} onClick={() => _contentUpdateSliderItem()}><IoMdRefreshCircle />Güncelle</button>
    //                 <button className="buttons" disabled={secim ? false : true} onClick={() => _contentUpdateSliderItemClear()}> <IoIosAdd />Yeni Oluştur</button>
    //             </div>
    //         </div>
    //     </Modal>
    //     <div className="sol">
    //         <List
    //             baslik={"İletişim Platformları"}
    //             ekle={showModalAndClear}
    //             sil={_clickDel}
    //             edit={_clickEvent}
    //             data={dataBaslik}
    //         />
    //     </div>
    //     <Sag>
    //         <p>Sitedeki ziyaretçileri mesajlaşmayı sağlıcak uygulamaları yönetebildiğniz kısım.
    //      <i>"Ekle"</i> butonu ile yeni bir platform ekleyebilirsiniz.</p>
    //         <h5>Örneğin:</h5>
    //         <ul>
    //             <li>Whatsapp</li>
    //             <li>Hangouts</li>
    //         </ul>
    //     </Sag>
    // </div>
}
Platforms.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/platforms',
        component: Platforms,
    },
    name: 'Platformlar',
    icon: <DevicesOther fontSize="small"/>,
    hideMenu: false,
    manage: true
};