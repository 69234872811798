import React from 'react';
import { TeeTimeSC, AllWrap, Wrapper, Splitter } from './style'
import { _paketdenIcerikSil } from '../services'
export default (props) => {
    console.log(props.values)
    function sil(x) {
        _paketdenIcerikSil(x)
            .then(function (data) {
                if (!data.data.error) {
                    // toast.success('Paket Silindi')
                    // _listeGuncelle();
                    // _yeniSayfa();
                } else {
                    // toast.error('Paket silme başarısız.')
                }
            });
    }
    return <TeeTimeSC>
        {/* {sahasCreator ? sahasCreator(props.art).map(x => x) : false} */}
        {props.values ? props.values.sort((c, d) => c.sira_no - d.sira_no).map((x, index) => {
            return <AllWrap key={index} index={index} >
                <Wrapper>
                    <Splitter>
                        <label>
                            <span>Sıra No #{index + 1}</span>
                            <input
                                name="sira_no"
                                type="textbox"
                                value={x.sira_no || ""}
                                onChange={(e) => props.change(e, index)}
                            />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>İçerik #{index + 1}</span>
                            <input
                                name="name"
                                type="textbox"
                                value={x.name || ""}
                                onChange={(e) => props.change(e, index)}
                            />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>İçerik Uzun #{index + 1}</span>
                            <input
                                name="name_long"
                                type="textbox"
                                value={x.name_long || ""}
                                onChange={(e) => props.change(e, index)}
                            />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>İşlemler</span>
                            <button onClick={(e) => {
                                if (x.id) {
                                    sil(x.id)
                                    props.delete(e, index)
                                } else {
                                    props.delete(e, index)
                                }
                            }}>Sil</button>
                        </label>
                    </Splitter>
                </Wrapper>
            </AllWrap>
        }) : false}
    </TeeTimeSC>
}