import React from "react";
import PropTypes from "prop-types";
import { ModalSC } from './style'

import { IoIosCloseCircle } from "react-icons/io";
export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };
    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <ModalSC id="modal">
                <div className="modalwrapper">
                    <h2>{this.props.baslik}</h2>
                    <IoIosCloseCircle onClick={this.onClose} />
                    <div className="content">{this.props.children}</div>
                    {/* <div class="actions">
                        <button class="toggle-button" onClick={this.onClose}>
                            Kapat
                        </button>
                    </div> */}
                </div>

            </ModalSC>
        );
    }
}
Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    baslik:PropTypes.string,
};
