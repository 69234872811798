import React, { useState } from 'react';
import { ProgressSC,ProgressDoneSC } from './styles'
export default (props) => {
    const [style, setStyle] = useState({});
    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${props.done}%`
        }

        setStyle(newStyle);
    }, 200);
    console.log(props.done)
    return <ProgressSC>
            <ProgressDoneSC style={style}>
                {props.done}%
        </ProgressDoneSC>
    </ProgressSC>
}