import React, { useEffect, useState } from 'react';
import { UrlContext } from '../../../stores/url'
import { Wrapper, UL, Li } from './styles'
import { _images, _silResim } from '../services'
const API_URL = UrlContext._currentValue;
export default (props) => {
    // const [imageList, setImageList] = useState(false);
    // useEffect(() => {
    //     _images(props.gallery_id).then(
    //         (res) => {
    //             console.log(res)
    //             setImageList(res.data.data)
    //         }
    //     )
    // }, [props.gallery_id])
    // function resimSil(x) {
    //     _silResim(x).then((res) => {
    //         _images(props.gallery_id).then(
    //             (res) => {
    //                 console.log(res)
    //                 setImageList(res.data.data)
    //             }
    //         )
    //     })
    // }
    return props.imageList ? <Wrapper>
        {props.imageList && "Yüklü Olan"}
        <UL>
            <Li >
                <img
                    src={API_URL + '/public/uploads/multislider/photo-' + props.imageList}
                    alt={props.imageList} />
                <span>{props.imageList}</span>
            </Li>
        </UL>
    </Wrapper>
        : false
}