import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom'
import { Button,TextField, Switch, FormGroup, FormLabel, FormControlLabel,Box } from '@mui/material';
// import { FormWrapperSc } from './styles'
import service from './services'
import { useHistory } from "react-router-dom";
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr } from './editorInitial/';
import { PlaceHolderTr, PlaceHolderCz, PlaceHolderDe, PlaceHolderEn, PlaceHolderRu } from './editorInitial/placeHolder'
import { toolbarDefine } from '../../utils/editorToolbar'
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import WYSIWYG from '../../components/WYSIWYG'
function AddEdit() {
    // const { id } = match.params;
    let { id } = useParams();
    const isAddMode = !id;
    let history = useHistory();
    const [user, setUser] = useState({});
    const [diller, setDiller] = useState(false);
    
    const { control, handleSubmit, watch, setValue, formState: { errors } } = useForm();

    useEffect(() => {
        const fields = ['name', 'list_sira_no', 'map','tr','en','de','cz','ru'];
        const aciklamafields = ['tr','en','de','cz','ru'];

        service.langsort().then((res) => {
            console.log(res)
            console.log("res")
            res.data.data.map((x) => {

                fields.push(x.code)
            })
            setDiller(res.data.data);
        });
        console.log(fields)
        if (!isAddMode) {
            service.detail(id).then(user => {
                console.log(user)

                fields.forEach(field => setValue(field, user.data.data[field]));
                aciklamafields.forEach(field => setValue(field, user.data.data.aciklama[0][field]))
                setUser(user);
            });
        }
    }, []);
    
    function onSubmit(data) {
        console.log(data)
        return isAddMode
            ? createUser(data)
            : updateUser(id, data);
    }

    function createUser(data) {
        return service.save(data)
            .then(() => {
                toast.success('Ekleme başarılı.')
                history.push('.');
            })
            .catch((err) => {
                toast.error('Ekleme başarısız.')
                console.log(err)
            });
    }

    function updateUser(id, data) {
        console.log(id)
        return service.update(data, id)
            .then(() => {
                toast.success('Güncelleme başarılı.')
                history.push('..');
            })
            .catch((err) => {
                toast.error('Güncelleme başarısız.')
                console.log(err)
            });
    }
    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormLabel>Platform {!isAddMode ? "Düzenle" : "Ekle"}</FormLabel>
                <FormGroup column>
                    <Controller
                        render={({ field }) => <TextField {...field} label="İsim" margin="normal" variant="outlined" />}
                        control={control}
                        name="name"
                        defaultValue=""
                    />
                    <Controller
                        render={({ field }) => <TextField {...field} label="Sıra No" margin="normal" variant="outlined" />}
                        control={control}
                        name="list_sira_no"
                        defaultValue=""
                    />
                    <Controller
                        render={({ field }) => <TextField {...field} label="Harita" margin="normal" variant="outlined" />}
                        control={control}
                        name="map"
                        defaultValue=""
                    />
                </FormGroup>
                <FormGroup>
                    <Controller
                        render={({ field }) => <WYSIWYG {...field} />}
                        // as={<WYSIWYG />}
                        name="tr"
                        control={control}
                    />
                    <Controller
                        render={({ field }) => <WYSIWYG {...field} />}
                        // as={<WYSIWYG />}
                        name="en"
                        control={control}
                        defaultValue=''
                    />
                    <Controller
                        render={({ field }) => <WYSIWYG {...field} />}
                        // as={<WYSIWYG />}
                        name="ru"
                        control={control}
                        defaultValue=''
                    />
                    <Controller
                        render={({ field }) => <WYSIWYG {...field} />}
                        // as={<WYSIWYG />}
                        name="de"
                        control={control}
                        defaultValue=''
                    />
                    <Controller
                        render={({ field }) => <WYSIWYG {...field} />}
                        // as={<WYSIWYG />}
                        name="cz"
                        control={control}
                        defaultValue=''
                    />
                </FormGroup>

                <FormGroup row >
                    <Box m={1} ml={0}>
                        <Button variant="contained" color="primary" type="submit">
                            Kaydet
                    </Button>
                    </Box>
                    <Box m={1}>
                        <Button component={Link} variant="contained" color="secondary" to={isAddMode ? '.' : '..'}>
                            İptal
                    </Button>
                    </Box>
                </FormGroup>
                {errors.code && <span>This field is required</span>}
            </form >
        </Box>
    );
}
export { AddEdit }