import styled from 'styled-components'
export const FormWrapper = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
flex-wrap:wrap;
`
export const Tasiyici = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 10;
  height: calc(100vh - 61px);
  overflow: auto;
  justify-content: flex-start;
  .sol {
              display: flex;
              flex-grow: 0;
              position: sticky;
              flex-basis: 100%;
              top: 0;
              flex-shrink: 0;
              transition: 500ms;
              &:hover {
                transition: 500ms;
                -webkit-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
                box-shadow: inset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
              }
              .liste {
                width: 100%;
                overflow: auto;
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
                    background-color: #f5f5f5 !important;
                }

                &::-webkit-scrollbar {
                    width: 6px !important;
                    background-color: #f5f5f5 !important;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgb(6, 162, 135) !important;
                }
                .listviewer {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 20px;
                  overflow: auto;
                  z-index: 100;
                  &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
                        background-color: #f5f5f5 !important;
                    }

                    &::-webkit-scrollbar {
                        width: 6px !important;
                        background-color: #f5f5f5 !important;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(6, 162, 135) !important;
                    }
                  h3 {
                    margin-top: 0;
                    border-bottom: 4px solid rgb(6, 162, 135);;
                    padding-bottom: 2px;
                    display: inline-block;
                  }
                  .aciklayici {
                    float: left;
                    width: 100%;
                    margin: 10px;
                    padding: 0px 20px;
                    box-sizing: border-box;
                    span {
                      text-align: right;
                      line-height: 10px;
                      width: 50%;
                      float: left;
                      &:last-child {
                        &::before {
                          content: "•";
                          font-size: 50px;
                          line-height: 0px;
                          vertical-align: top;
                          color: #f98923;
                        }
                      }
                      &::before {
                        content: "•";
                        font-size: 50px;
                        line-height: 0px;
                        vertical-align: top;
                        color: rgb(6, 162, 135);;
                      }
                    }
                  }

                  ul {
                    margin: 0;
                    padding: 0;
                    float: left;
                    width: 100%;
                    .altcat {
                      border-left: 2px solid #f98923;
                      text-transform: lowercase;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      &:hover {
                        z-index: 10;
                        background: #f98923;
                        color: #f5f6f8;
                        box-shadow: 0px 2px 10px #f98923;
                        text-transform: capitalize;
                      }
                      &:first-letter {
                        text-transform: capitalize;
                      }
                    }
                    li {
                      //text-transform: lowercase;
                      width: 100%;
                      cursor: pointer;
                      list-style: none;
                      padding: 10px 5px;
                      margin: 4px 0px;
                      box-sizing: border-box;
                      border-left: 2px solid rgb(6, 162, 135);;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      &::first-letter {
                        text-transform: capitalize;
                      }
                      span {
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 70%;
                        float: left;
                        //display: contents;
                        font-size: 14px;
                        text-transform: capitalize;
                      }
                      &:hover {
                        z-index: 10;
                        background: rgb(6, 162, 135);;
                        color: #f5f6f8;
                        border-radius: 6px;
                        box-shadow: 0px 2px 10px rgb(6, 162, 135);;
                        //text-transform: lowercase;
                        text-transform: capitalize;
                        text-overflow: ellipsis;
                        &:first-letter {
                          text-transform: capitalize;
                        }
                        svg {
                          color: #f5f6f8;
                        }
                      }
                      
                    }
                  }
                }
                .listviewer2 {
                  width: 100%;
                  float: left;
                  box-sizing: border-box;
                  padding: 20px;
                  height: auto;
                  h3 {
                    margin-top: 0;
                  }
                  ul {
                    margin: 0;
                    padding: 0;
                    li {
                      cursor: pointer;
                      list-style: none;
                      padding: 5px;
                      button {
                        background: none;
                        border: none;
                        img {
                          width: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
  `
export const Sag = styled.div`
padding:20px;
box-sizing:border-box;
display:flex;
flex-direction:column;
justify-content:flex-start;
`
export const Liste = styled.div`
flex-basis:100%;
  overflow: hidden;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #f5f5f5 !important;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    background-color: #f5f5f5 !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(6, 162, 135) !important;
  }
`
export const ButtonSC = styled.button`
 border:none;
 border-radius:4px;
 background:#2ecc71;
 font-size:18px;
 color:white;
 box-sizing: border-box;
 cursor:pointer;
 svg {
                width: 20px;
                height: 20px;
                vertical-align: text-bottom;
            }
            &:hover{
                transition:300ms;
                background:none;
                color:#2ecc71;
            }
`
export const ListviewerTop = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    flex-wrap:wrap;
    padding-bottom:20px;
    box-sizing:border-box;
    h3 {
        margin: 0;
        border-bottom: 4px solid rgb(6, 162, 135);
        padding-bottom: 2px;
        /* display: inline-block; */
    }
    .aciklayici {
                    margin-top: 30px;
                    box-sizing: border-box;
                    flex-basis:100%;
                    span {
                      text-align: center;
                      &:last-child {
                        &::before {
                          content: "•";
                          font-size: 50px;
                          line-height: 0px;
                          vertical-align: top;
                          color: #f98923;
                        }
                      }
                      &::before {
                        content: "•";
                        font-size: 50px;
                        line-height: 0px;
                        vertical-align: top;
                        color: rgb(6, 162, 135);
                      }
                      line-height: 10px;
                      width: 50%;
                      float: left;
                    }
                  }
`
export const Listviewer = styled.div`
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      overflow: hidden;
      z-index: 100;
      height:100%;
      display:flex;
      position:relative;
      flex-direction:column;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        background-color: #f5f5f5 !important;
      }

      &::-webkit-scrollbar {
        width: 6px !important;
        background-color: #f5f5f5 !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(6, 162, 135) !important;
      }
`
export const ListviewerUl = styled.ul`
                    margin: 0;
                    padding: 0;
                    float: left;
                    width: 100%;
                    overflow:auto;
                    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        background-color: #f5f5f5 !important;
      }

      &::-webkit-scrollbar {
        width: 6px !important;
        background-color: #f5f5f5 !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(6, 162, 135) !important;
      }
                    .altcat {
                      border-left: 2px solid  #f98923;
                      text-transform: lowercase;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      &:hover {
                        z-index: 10;
                        background: #f98923;
                        color: #f5f6f8;
                        box-shadow: 0px 2px 10px  #f98923;
                        text-transform: capitalize;
                      }
                      &:first-letter {
                        text-transform: capitalize;
                      }
                    }
                    li {
                      width: 100%;
                      cursor: pointer;
                      list-style: none;
                      padding: 10px 5px;
                      margin: 4px 0px;
                      box-sizing: border-box;
                      border-left: 2px solid rgb(6, 162, 135);
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      transition:300ms;
                      &::first-letter {
                        text-transform: capitalize;
                      }
                      span {
                        white-space: normal;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 70%;
                        float: left;
                        //display: contents;
                        font-size: 14px;
                        text-transform: capitalize;
                      }
                      &:hover {
                        z-index: 10;
                        background: rgb(6, 162, 135);
                        color: #f5f6f8;
                        border-radius: 6px;
                        box-shadow: 0px 2px 10px rgb(6, 162, 135);
                        text-transform: capitalize;
                        text-overflow: ellipsis;
                        transition:300ms;
                        &:first-letter {
                          text-transform: capitalize;
                        }
                        svg {
                          color: #f5f6f8;
                        }
                      }
                      
                    }
`
export const ButtonWrapper = styled.div`
    float:right;
        button {
            background: none;
            border: none;
            float: left;
            cursor: pointer;
            &:first-child{
                color:#3498db;
            }
            &:last-child{
                color:#e74c3c;
            }
            img {
                width: 10px;
            }
            svg {
                width: 20px;
                height: 20px;
            }
        }
`