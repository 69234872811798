import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import ListViewer from '../../components/listSayfalar';
import { FaQuestionCircle,FaPager } from 'react-icons/fa';
import StepZilla from "react-stepzilla";
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr,initialStateFr } from './editorInitial/'
// import { toast } from 'react-toastify';
import Editors from './editorInitial/editors'
import ButtonGroup from './buttonGroup'
import SayfaAd from './sayfaAdi'
import PropTypes from 'prop-types';
/*
TODO: hooka çevir
TODO: toast ile bildirimleri sağla
*/
class EditorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorStateTr: initialStateTr,
      editorStateEn: initialStateEn,
      editorStateRu: initialStateRu,
      editorStateDe: initialStateDe,
      editorStateCz: initialStateCz,
      editorStateFr: initialStateFr,
      secim: false,
      preview: "",
      liste: false
    };
    this._contentSave = this._contentSave.bind(this);
    this._clickEvent = this._clickEvent.bind(this);
    this._contentUpdate = this._contentUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComponent = this.handleChangeComponent.bind(this);
    this.handleChangeComponentUst = this.handleChangeComponentUst.bind(this);
    this._clickDel = this._clickDel.bind(this);
    this._yeniSayfa = this._yeniSayfa.bind(this);
    this.onChange = (editorState) => {
      this.setState({ editorState })
    };
    this._inputChange = this._inputChange.bind(this)
    this._stateConverter = this._stateConverter.bind(this);
  }
  

  _yeniSayfa() {
    this.setState({
      data: false,
      secim: false,
      dataBaslik: false,
    })
  }
  async _listeGuncelle() {
    console.log("Guncelle");
    await fetch(this.props.apiUrl + "/treatment").then(response => response.json())
      .then((gelen) => {
        // console.log(gelen);
        this.setState({ liste: gelen.data })
      });
  }
  _clickDel(x) {
    console.log(x)
    fetch(this.props.apiUrl + '/treatmentdelete', {

      crossDomain: true,
      xhrFields: {
        withCredentials: false,
      },
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ treatment_id: x.id })
    })
      .then(response => this._listeGuncelle())
    // axios.delete(
    //   this.props.apiUrl+'treatment',
    //   {headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //   },

    //   data:{
    //     body: JSON.stringify({ treatment_id: x.id })
    //   }}
    // ).then(response => this._listeGuncelle());
  }
  _contentSave() {
    let self = this;
    console.log("tıkladım")
   
    let htmlEn = draftToHtml(convertToRaw(this.state.editorStateEn.getCurrentContent()))
    let htmlRu = draftToHtml(convertToRaw(this.state.editorStateRu.getCurrentContent()))
    let htmlDe = draftToHtml(convertToRaw(this.state.editorStateDe.getCurrentContent()))
    let htmlTr = draftToHtml(convertToRaw(this.state.editorStateTr.getCurrentContent()))
    let htmlCz = draftToHtml(convertToRaw(this.state.editorStateCz.getCurrentContent()))
    let htmlfr = draftToHtml(convertToRaw(this.state.editorStateFr.getCurrentContent()))
   

    // console.log(html)
    fetch(this.props.apiUrl + '/treatment', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        treatment: { 
          nameen: this.state.secim.nameen, 
          namefr: this.state.secim.namefr, 
          namede: this.state.secim.namede, 
          namecz: this.state.secim.namecz, 
          nametr: this.state.secim.nametr,
          nameru: this.state.secim.nameru,
          detailen: htmlEn, 
          detailfr: htmlfr, 
          detailde: htmlDe,  
          detailcz: htmlCz,
          detailru:htmlRu,
          detailtr:htmlTr,
          MainSub: this.state.secim.checked || false, 
          component: this.state.secim.component || "Basit", 
          path: `/${this.state.secim.nameen.toLowerCase().replace(/\s/g, '')}`, 
          ust_category_id: this.state.secim.ust_category_id || 0 
        } 
      })
    })
      .then(response => response.json())
      .then(function (data) {
        self.setState({ data })
        if (!data.error) {
          self._listeGuncelle();
        }
      });


  }
  _stateConverter(data) {
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  _clickEvent(e) {
    let self = this;
    fetch(this.props.apiUrl + `/treatment/${e.id}`).then(response => response.json())
      .then((gelen) => {
        // console.log(gelen)

        const editorStateTr = this._stateConverter(gelen.data.detailtr);
        const editorStateEn = this._stateConverter(gelen.data.detailen);
        const editorStateRu = this._stateConverter(gelen.data.detailru);
        const editorStateDe = this._stateConverter(gelen.data.detailde);
        const editorStateCz = this._stateConverter(gelen.data.detailcz);
        const editorStateFr = this._stateConverter(gelen.data.detailfr);

        self.setState({
          editorStateTr: editorStateTr,
          editorStateRu: editorStateRu,
          editorStateDe: editorStateDe,
          editorStateEn: editorStateEn,
          editorStateCz: editorStateCz,
          editorStateFr: editorStateFr,
          secim: gelen.data
        })
     
      });
  }
  onEditorStateChangeTr = editorStateTr => {
    // console.log(editorState)
    this.setState({
      editorStateTr
    });

  }
  onEditorStateChangeFr = editorStateFr => {
    // console.log(editorState)
    this.setState({
      editorStateFr
    });

  }
  onEditorStateChangeDe = editorStateDe => {
    // console.log(editorState)
    this.setState({
      editorStateDe
    });

  }
  onEditorStateChangeCz = editorStateCz => {
    // console.log(editorState)
    this.setState({
      editorStateCz
    });

  }
  onEditorStateChangeEn = editorStateEn => {
    // console.log(editorState)
    this.setState({
      editorStateEn
    });

  }
  onEditorStateChangeRu = editorStateRu => {
    // console.log(editorState)
    this.setState({
      editorStateRu
    });

  }
  _inputChange(e) {
    let secimProp = { ...this.state.secim }
    let alan = `name${e.target.name}`;

    secimProp[alan] = e.target.value;
    this.setState({ secim: secimProp })
  }
  async componentDidMount() {
    console.log("Gete Tıkladım");
    fetch(this.props.apiUrl + "/treatment").then(response => response.json())
      .then((gelen) => {
        // console.log(gelen);
        this.setState({ liste: gelen.data })
      });
  }
  _contentUpdate() {
    let self = this;
    // console.log("tıkladım")
    console.log(this.state.secim)
    const { id, nameen, namefr, namede, namecz,nametr,nameru, MainSub, component, ust_category_id } = this.state.secim;
    console.log(MainSub)
    let htmlen = draftToHtml(convertToRaw(this.state.editorStateEn.getCurrentContent()))
    let htmlru = draftToHtml(convertToRaw(this.state.editorStateRu.getCurrentContent()))
    let htmlde = draftToHtml(convertToRaw(this.state.editorStateDe.getCurrentContent()))
    let htmltr = draftToHtml(convertToRaw(this.state.editorStateTr.getCurrentContent()))
    let htmlCz = draftToHtml(convertToRaw(this.state.editorStateCz.getCurrentContent()))
    let htmlfr = draftToHtml(convertToRaw(this.state.editorStateFr.getCurrentContent()))
    fetch(this.props.apiUrl + '/treatmentguncelle', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        treatment_id: id, 
        treatment: { 
          nametr: nametr, 
          nameru: nameru, 
          nameen: nameen, 
          namefr: namefr, 
          namede: namede, 
          namecz: namecz, 
          MainSub: MainSub, 
          detailen: htmlen, 
          detailtr: htmltr, 
          detailru: htmlru, 
          detailfr: htmlfr, 
          detailde: htmlde, 
          detailcz: htmlCz, 
          component: component, 
          ust_category_id: ust_category_id } })
    })
      .then(response => response.json())
      .then(function (data) {
        self.setState({ data })
        if (!data.error) {
          self._listeGuncelle();
        }
      });

  }
  handleChangeComponent(e) {
    // this.setState({value: event.target.value});
    const { value } = e.target
    let secimProp = { ...this.state.secim }
    let alan = `component`;

    secimProp[alan] = value;
    this.setState({ secim: secimProp })
    // this.setState({
    //   secim: checked
    // })
  }
  handleChangeComponentUst(e) {
    // this.setState({value: event.target.value});
    const { value } = e.target
    let secimProp = { ...this.state.secim }
    let alan = `ust_category_id`;

    secimProp[alan] = value;
    this.setState({ secim: secimProp })
    // this.setState({
    //   secim: checked
    // })
  }
  handleChange = (e) => {
    const { checked } = e.target
    let secimProp = { ...this.state.secim }
    let alan = `checked`;

    secimProp[alan] = checked;
    this.setState({ secim: secimProp })
    // this.setState({
    //   secim: checked
    // })
  }
  render() {
    const { 
      editorStateEn,
      editorStateRu,
      editorStateTr,
      editorStateFr, 
      editorStateDe, 
      editorStateCz, secim, liste } = this.state;
    console.log(secim)
    const steps = [
      {
        name: 'Ad', component: <SayfaAd
          baslik={"Sayfa Adı"}
          secim={secim}
          change={this._inputChange}
        />
      },
      {
        name: 'Yerleşim', component:<div className="inputs"><div className="editorName">
          <h4>Sayfa Menü Konumu ve Yerleşim</h4>
          <div className="checktasiyici">
            <input type="checkbox"
              onChange={e => this.handleChange(e)}
              checked={(secim.MainSub || secim.checked) ? true : false}
              defaultChecked={secim.MainSub} />

            <label>Alt Menüde Göster</label>
            <span title="Ana Sayfa iki menuden oluşmaktadır.Bu seçenek işaretlenir ise link alt menu'de yerini alır."><FaQuestionCircle /></span>
          </div>
          <fieldset disabled={(secim.MainSub || secim.checked) ? "" : "disabled"} className="checktasiyici">
            <label>
              Üst Kategori Seç:
        </label>
            <select disabled={(secim.MainSub || secim.checked) ? "" : "disabled"} value={secim.ust_category_id} onChange={e => this.handleChangeComponentUst(e)}>
              <option value="secilmedi">Seçilmedi</option>
              {liste ? liste.map((x, i) => {
                return <option key={i} value={x.id}>{x.nameen}</option>
              }) : false}
            </select>
            <span title="Alt Menude göster işaretlenirse aktif olur.Alt menudeki hangi açılır pencerenin altına geliceğini belirlemek için kullanılır."><FaQuestionCircle /></span>
          </fieldset>
          <div className="checktasiyici">
            <label>
              Yerleşim Seç:
        </label>
            <select value={secim.component} onChange={e => this.handleChangeComponent(e)}>
              <option value="Basit">Basit</option>
              <option value="Tacos">Detay</option>

            </select>
            <span title="Basit seçilmelidir.İlerde detay kısmı değiştirilebilir hale gelecektir."><FaQuestionCircle /></span>
          </div>
        </div></div> 
      },
      {
        name: 'İçerik', component: <Editors
          editorStateEn={editorStateEn}
          editorStateRu={editorStateRu}
          editorStateDe={editorStateDe}
          editorStateTr={editorStateTr}
          editorStateCz={editorStateCz}
          editorStateFr={editorStateFr}
          onEditorStateChangeEn={this.onEditorStateChangeEn}
          onEditorStateChangeRu={this.onEditorStateChangeRu}
          onEditorStateChangeDe={this.onEditorStateChangeDe}
          onEditorStateChangeTr={this.onEditorStateChangeTr}
          onEditorStateChangeCz={this.onEditorStateChangeCz}
          onEditorStateChangeFr={this.onEditorStateChangeFr}
          Fr={true}
        />
      },
      { name: 'Bitir', component:<ButtonGroup 
       secim={secim}
       update={this._contentUpdate}
       save={this._contentSave}
       ekle={this._yeniSayfa}
      /> }
    ];
    return (
      <div className="tasiyici">
        <div className="sol">
          <div className="liste">
            {liste ? <ListViewer title="Sayfalar" liste={this.state.liste} clickDel={this._clickDel} clickEvent={this._clickEvent} /> : <div className="loading">"Yükleniyor..."</div>}
          </div>
        </div>
        <div className="sag">
          <div className="baslik">
            <h4>Sayfa İçeriği</h4>
          </div>

          <StepZilla 
            steps={steps} 
            preventEnterSubmission={true}
            nextButtonCls="ileri"
            backButtonCls='geri'
            />
         
      

        </div>

      </div>);
  }
}
EditorContainer.propTypes = {
  apiUrl: PropTypes.string.isRequired
};


export default {
    routeProps: {
        path: '/wys2',
        component: EditorContainer
    },
    name: 'Sayfa Oluştur',
    icon: <FaPager fontSize="small"/>,
    hideMenu:false,
}
