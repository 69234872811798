import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;

export const _analyticslistesi = () => {
    return axios.get(API_URL + '/language', { headers: authHeader() })
}
export const _analyticsDetay = (id) => {
    return axios.get(API_URL + `/language/${id}`, { headers: authHeader() })
}
export const _analyticsSil = (id) => {
    return axios.delete(API_URL + `/language/${id}`, { headers: authHeader() })
}
export const _analyticsKaydet = (data) => {
    const params = {
        code: data.code,
        name: data.name,
        active: data.active,
    }
    return axios.post(API_URL + `/language`, { ...params }, { headers: authHeader() })
}

export const _analyticsGuncelle = (data, id) => {
    return axios.put(API_URL + `/language/${id}`, { ...data }, { headers: authHeader() })
}