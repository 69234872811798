export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user)
    if (user && user.token) {
        // for Node.js Express back-end
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }
}