import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import service from './services'
import { MdDeleteForever, MdModeEdit } from "react-icons/md";

import { Button, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
export default function Liste(props) {
    const [data, setData] = useState(false);
    const [secondary, setSecondary] = React.useState(true);
    let history = useHistory();
    useEffect(() => {
        service.sort()
            .then(list => {
                setData(list.data.data)
            })
    }, [])
    function getir() {
        service.sort()
            .then(list => {
                setData(list.data.data)
            })
    }
    function handleClick() {
        history.push(`${props.path}/add`);
    }
    function handleEdit(id) {
        history.push(`${props.path}/edit/${id}`);
    }
    function _clickDel(x) {
        service.delete(x)
            .then(function (data) {
                if (!data.error) {
                    toast.success('Silme başarılı.')
                    getir();
                } else {
                    toast.error('Silme yapılamadı.')
                }
            });
    }
    return <div className="liste">
    <div className="listviewer">
        <Box display="flex" alignItems="center" flexDirection="row" justifyContent="space-between" textAlign="left">
            {props.baslik}
            <Button variant="contained" color="primary" onClick={(e) => handleClick()}>
                Ekle
            </Button>
        </Box>
       

        <List component="nav" aria-label="main mailbox folders">
            {data ? data.length > 0 ? data.map((x, i) => {
                if (x) {
                    return <ListItem>
                        <ListItemText
                            primary={x.name}
                            secondary={secondary ? x.trackedvalue : null}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit">
                                <EditIcon onClick={() => handleEdit(x.id)} />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={() => _clickDel(x.id)} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                } else {
                    return false
                }
            }) : false : false}
        </List>
        <Divider />

    </div>
</div>
    // return <div className="liste">
    //     <div className="listviewer">
    //         <h3>{props.baslik}</h3>
    //         <button name="ekle" onClick={(e) => props.ekle(e)}>Ekle</button>

    //         <ul>
    //             {props.data ? props.data.length > 0 ? props.data.map((x, i) => {
    //                 if (x) {
    //                     return <li key={i} >
    //                         <span>{x.name}</span>
    //                         <button className="listbutton" onClick={() => props.sil(x.id)}><MdDeleteForever /></button>
    //                         <button className="listbutton" onClick={() => props.edit(x)}><MdModeEdit /></button>
    //                     </li>
    //                 }else{
    //                     return false
    //                 }
    //             }) : false : false}

    //         </ul>

    //     </div>
    // </div>
}
Liste.propTypes = {
    baslik: PropTypes.string.isRequired,
    ekle:PropTypes.func.isRequired,
    sil:PropTypes.func.isRequired,
    edit:PropTypes.func.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
      ]),
  };