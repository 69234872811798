import React, { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import StepZilla from "react-stepzilla";
import htmlToDraft from 'html-to-draftjs';
import '../../styles/react-draft-wysiwyg.css'
import '../../styles/Draft.css'
import { toast } from 'react-toastify';
import ListViewer from './Liste';
import { FaQuestionCircle, FaBed } from 'react-icons/fa';
import { toolbarDefine } from '../../utils/editorToolbar'
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr } from './editorInitial/';
import { PlaceHolderTr, PlaceHolderCz, PlaceHolderDe, PlaceHolderEn, PlaceHolderRu } from './editorInitial/placeHolder'
import service from './services'
import List from './list'
import { Route, Switch } from 'react-router-dom';
import { AddEdit } from './AddEdit'
import  AddEditV2 from './addEditv2'
import { Grid, Box } from '@mui/material'
import HotelIcon from '@mui/icons-material/Hotel';
function HotelDetail(props) {
  const [editorStateTr, setEditorStateTr] = useState(initialStateTr);
  const [editorStateEn, setEditorStateEn] = useState(initialStateEn);
  const [editorStateRu, setEditorStateRu] = useState(initialStateRu);
  const [editorStateDe, setEditorStateDe] = useState(initialStateDe);
  const [editorStateCz, setEditorStateCz] = useState(initialStateCz);
  const [secim, setSecim] = useState(false);
  const [liste, setListe] = useState(false);
  return <Switch>
    <Route exact path={`${props.path}`}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item sm={12} md={3} xl={3}>
          <List
            baslik={"Hoteller"}
            path={props.path}
          />
        </Grid>
        <Grid item sm={12} md={9} xl={9}>
          <Box p={5}>
            <p>Bu bölümden siteye ek dil eklenebilir.
                   <i>"Ekle"</i> butonundan yeni bir dil ekleyerek yönetim ve ziyaretçilerin gördüğü sayfalardaki dil kolonlarını oluşturup aktif veya deaktif edebilirsiniz.</p>
            <article>Eklenmesi gereken üç alan bulunmaktadır.Bunlar sırasıyla code,name,ve durum alanlarıdır.</article>
            <p>Code:Dilin kısaltmasıdır.</p>
            <p>Name:Dilin ziyaretçi sayfasından değişimini yaparken görünen alandır.</p>
            <p>Durum:Önceden girilmiş ama artık ihtiyaç duyulmayan herhangi bir dili pasif hale getirip ziyaretçilerin görmemesini sağlayabilirsiniz.</p>
            <h5>Örneğin:</h5>
            <ul>
              <li>Code : en</li>
              <li>Name : English</li>
              <li>Durum : Tikli</li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Route>
    <Route path={`${props.path}/add`}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={4} xl={4}>
          {/* <AddEdit /> */}
          <AddEdit/>
        </Grid>
      </Grid>
    </Route>
    <Route path={`${props.path}/edit/:id`}>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <AddEdit />
        </Grid>
      </Grid>
    </Route>
  </Switch >
  // useEffect(() => {
  //   service.sort()
  //     .then((gelen) => {
  //       setListe(gelen.data.data)
  //     });
  // }, [])
  // function _yeniSayfa() {
  //   setSecim(false)
  // }
  // function _listeGuncelle() {
  //   service.sort()
  //     .then((gelen) => {
  //       setListe(gelen.data.data)
  //     });
  // }
  // function _clickDel(x) {
  //   service.delete(x.id)
  //     .then(function (res) {
  //       if (!res.data.error) {
  //         toast.success('Hotel Silindi')
  //         _listeGuncelle();
  //         _yeniSayfa();
  //       } else {
  //         toast.error('Hotel silme başarısız.')
  //       }
  //     });
  // }
  // function _contentSave() {
  //   service.save({
  //     name: secim.name,
  //     tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
  //     en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
  //     ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
  //     de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
  //     cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
  //     map: secim.map,
  //     list_sira_no: secim.list_sira_no,
  //   })
  //     .then(function (data) {
  //       if (!data.data.error) {
  //         toast.success('Hotel eklendi')
  //         _listeGuncelle();
  //         _yeniSayfa();
  //       } else {
  //         toast.error('Hotel ekleme başarısız.')
  //       }
  //     });


  // }
  // function _stateConverter(data) {
  //   const blocksFromHtml = htmlToDraft(data);
  //   const { contentBlocks, entityMap } = blocksFromHtml;
  //   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //   const editorState = EditorState.createWithContent(contentState);
  //   return editorState;
  // }
  // function _clickEvent(e) {
  //   service.detail(e.id)
  //     .then((gelen) => {
  //       const editorStateTr = _stateConverter(gelen.data.data.aciklama[0].tr || PlaceHolderTr);
  //       const editorStateEn = _stateConverter(gelen.data.data.aciklama[0].en || PlaceHolderEn);
  //       const editorStateRu = _stateConverter(gelen.data.data.aciklama[0].ru || PlaceHolderRu);
  //       const editorStateDe = _stateConverter(gelen.data.data.aciklama[0].de || PlaceHolderDe);
  //       const editorStateCz = _stateConverter(gelen.data.data.aciklama[0].cz || PlaceHolderCz);
  //       setEditorStateTr(editorStateTr);
  //       setEditorStateEn(editorStateEn);
  //       setEditorStateRu(editorStateRu);
  //       setEditorStateDe(editorStateDe);
  //       setEditorStateCz(editorStateCz);
  //       setSecim({ ...gelen.data.data, aciklama_id: gelen.data.data.aciklama[0].id })

  //     });
  // }
  // function onEditorStateChangeEn(editorStateEn) {
  //   setEditorStateEn(editorStateEn);
  // }
  // function onEditorStateChangeTr(editorStateTr) {
  //   setEditorStateTr(editorStateTr);
  // }
  // function onEditorStateChangeRu(editorStateRu) {
  //   setEditorStateRu(editorStateRu);
  // }
  // function onEditorStateChangeDe(editorStateDe) {
  //   setEditorStateDe(editorStateDe);
  // }
  // function onEditorStateChangeCz(editorStateCz) {
  //   setEditorStateCz(editorStateCz);
  // }
  // function _inputChange(e) {
  //   let secimProp = { ...secim }
  //   let alan = `${e.target.name}`;
  //   secimProp[alan] = e.target.value;
  //   setSecim(secimProp);
  // }
  // function _contentUpdate() {
  //   const { id, name, map, aciklama_id, list_sira_no } = secim;
  //   service.update({
  //     aciklama_id: aciklama_id,
  //     name: name,
  //     tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
  //     en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
  //     ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
  //     de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
  //     cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
  //     map: map,
  //     list_sira_no: list_sira_no,
  //   }, id)
  //     .then(function (data) {
  //       if (!data.data.error) {
  //         toast.success('Hotel Güncellendi')
  //         _listeGuncelle();
  //         _yeniSayfa();
  //       } else {
  //         toast.error('Hotel güncelleme başarısız.')
  //       }
  //     });

  // }
  // const steps = [
  //   {
  //     name: 'Ad', component: <div className="inputs"><div className="editorName">
  //       <label>Hotel Adı</label>
  //       <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
  //       <input type="text" required value={secim.name || ""} name="name" placeholder="e.g: Hotel Plus" onChange={(e) => _inputChange(e)} />
  //       <label>Sıra No</label>
  //       <span title="Frontend'de hotel listesindeki sıralamayı ayarlar"><FaQuestionCircle /></span>
  //       <input type="text" required value={secim.list_sira_no || ""} name="list_sira_no" placeholder="e.g: 1" onChange={(e) => _inputChange(e)} />
  //       <label>Konum</label>
  //       <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
  //       <input type="text" required value={secim.map || ""} name="map" placeholder="e.g: 35.5542" onChange={(e) => _inputChange(e)} />

  //     </div></div>
  //   },

  //   {
  //     name: 'İçerik', component: <div className="wysiwyg">
  //       <Editor
  //         editorState={editorStateEn}
  //         onEditorStateChange={onEditorStateChangeEn}
  //         toolbar={toolbarDefine}
  //       />
  //       <Editor
  //         editorState={editorStateRu}
  //         onEditorStateChange={onEditorStateChangeRu}
  //         toolbar={toolbarDefine}
  //       />
  //       <Editor
  //         editorState={editorStateDe}
  //         onEditorStateChange={onEditorStateChangeDe}
  //         toolbar={toolbarDefine}
  //       />
  //       <Editor
  //         editorState={editorStateTr}
  //         onEditorStateChange={onEditorStateChangeTr}
  //         toolbar={toolbarDefine}
  //       />
  //       <Editor
  //         editorState={editorStateCz}
  //         onEditorStateChange={onEditorStateChangeCz}
  //         toolbar={toolbarDefine}
  //       />
  //     </div>
  //   },
  //   {
  //     name: 'Bitir', component: <div className="inputs"> <div className="editorName">
  //       <button disabled={secim.id ? false : true} onClick={_contentUpdate}>Güncelle</button>
  //       <button disabled={(secim && !secim.id) ? false : true} onClick={_contentSave}>Kaydet</button>
  //       <button disabled={secim ? false : true} onClick={_yeniSayfa}>Temizle</button>
  //     </div></div>
  //   }
  // ];
  // return <div className="tasiyici">
  //   <div className="sol">
  //     <div className="liste">
  //       {liste ? <ListViewer
  //         title="Hoteller"
  //         liste={liste}
  //         ekle={_yeniSayfa}
  //         clickDel={_clickDel}
  //         clickEvent={_clickEvent}
  //       /> : <div className="loading">"Yükleniyor..."</div>}
  //     </div>
  //   </div>
  //   <div className="sag">
  //     <div className="baslik">
  //       <h4>Hotel İçeriği</h4>
  //     </div>
  //     <StepZilla
  //       steps={steps}
  //       preventEnterSubmission={true}
  //       nextButtonCls="ileri"
  //       backButtonCls='geri'
  //     />
  //   </div>
  // </div>
}

export default {
  routeProps: {
    path: '/hotelv2',
    component: HotelDetail
  },
  name: 'Hotel',
  icon: <HotelIcon fontSize="small"/>,
  hideMenu: true,
  listCategory:2,
}
