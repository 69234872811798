import React from 'react';
import { toolbarDefine } from '../../../utils/editorToolbar'
import { Editor } from "react-draft-wysiwyg";

export default function Editors(props){
    return <div className="wysiwyg">
    <Editor
      editorState={props.editorStateEn}
      onEditorStateChange={props.onEditorStateChangeEn}
      toolbar={toolbarDefine}
    />
    <Editor
      editorState={props.editorStateRu}
      onEditorStateChange={props.onEditorStateChangeRu}
      toolbar={toolbarDefine}
    />
    <Editor
      editorState={props.editorStateDe}
      onEditorStateChange={props.onEditorStateChangeDe}
      toolbar={toolbarDefine}
    />
    <Editor
      editorState={props.editorStateTr}
      onEditorStateChange={props.onEditorStateChangeTr}
      toolbar={toolbarDefine}
    />
    <Editor
      editorState={props.editorStateCz}
      onEditorStateChange={props.onEditorStateChangeCz}
      toolbar={toolbarDefine}
    />
    {props.Fr?<Editor
      editorState={props.editorStateFr}
      onEditorStateChange={props.onEditorStateChangeFr}
      toolbar={toolbarDefine}
    />:false}
  </div>
}