import { Divider, Typography, Container, Item, Grid, Button, TextField, Box, FormLabel, FormGroup, Select, MenuItem, FormControl, FormControlLabel, InputLabel } from "@mui/material";
import { Field, FieldArray, FieldProps, Form, Formik, getIn, useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { generate } from "shortid";
import * as yup from "yup";
import { useHistory, Link, useParams } from "react-router-dom";
import service from './services'
const validationSchema = yup.object().shape({
    entitylabeltranslations: yup.array().of(
        yup.object().shape({
            name: yup.string().max(1000),
            value: yup.string(200)
        })
    ),
    // attr: yup.array().of(
    //     yup.object().shape({
    //         name: yup.string().max(1000),
    //         value: yup.string(200)
    //     })
    // )
});

const Input = ({ label, field, form: { errors } }) => {
    const errorMessage = getIn(errors, field.name);

    return (
        <FormGroup column>
            <TextField label={label} {...field} />
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        </FormGroup>
    );
};
const InputSelect = ({ entityTypeChange, entityTypeList, label, field, form: { errors } }) => {
    const errorMessage = getIn(errors, field.name);

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label">Varlık Türü</InputLabel>
            <Select labelId="demo-simple-select-label" label={label} {...field}>
                {entityTypeList.map((person) => (
                    <MenuItem key={person.id} value={person.id}>
                        {person.name}
                    </MenuItem>
                ))}
            </Select>
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        </FormControl>
    );
};

const AddEdit = () => {
    let history = useHistory();
    let { id } = useParams();
    const isAddMode = !id;
    const [entityTypeList, setEntityTypeList] = useState([]);
    const [languagelist, setLanguagelist] = useState(false);
    const [attributelist, setAttributeList] = useState(false);
    function get_entity_type_list() {
        service.sort_entity_type().then(user => {
            // setValue("entity_type_id", user.data.data)
            setEntityTypeList(user.data.data);
        });
    }
    function get_language_list() {
        service.languageList().then(incoming => {
            setLanguagelist(incoming.data.data)
            formik.setFieldValue('entitylabeltranslations', incoming.data.data)
        });
    }
    function get_entity_detail(pk) {
        service.detail(pk).then(async (user) => {
            console.log(user)
            let gelen = user.data.data;
            formik.setFieldValue('name', gelen.name)
            formik.setFieldValue('entitytypeId', gelen.entitytypeId)

            const etlt = languagelist.map((l, i) => {
                let dat = { ...l }
                const za = gelen.entitylabeltranslations.forEach((g) => {

                    if (l.id == g.languageId) {
                        dat.value = g.value
                        dat.entityId = g.entityId
                        // dat = {...dat,...g }
                    }

                    return dat
                });
                return dat
            })
            console.log(etlt)
            formik.setFieldValue('entitylabeltranslations', etlt)
            let attrlist = await get_attribute_list(gelen.entitytypeId).then((data) => data)
            const atls = attrlist.map((a, i) => {
                let dat = { ...a }
                const zi = gelen.entityattributevalues.forEach((e) => {
                    if (a.attributeId == e.attributeId && a.langId == e.languageId) {
                        dat.value = e.value;
                        dat.entityId = e.entityId
                    }
                    return dat
                })
                return dat
            })
            console.log({ 'gelenattr': gelen.entityattributevalues })
            console.log({ 'attrlist': attrlist })
            console.log({ 'atls': atls })
            formik.setFieldValue('attr', atls)
        });
    }
    async function get_attribute_list(entitytypeId) {
        let data = service.attributeList(entitytypeId).then(incoming2 => {
            let result = incoming2.data.data;

            let attrGroup = result.map((a, i) => a.attributegroup.attributegroupitems)
            let attrGroupItems = attrGroup.reduce((a, b) => [...a, ...b], [])
            setAttributeList(incoming2.data.data)
            let itemInLang = attrGroupItems.map((a) => {
                const la = languagelist.map((l) => {
                    const att = { ...a };
                    att.langId = l.id
                    att.langName = l.name
                    return att
                })
                return la
            })
            let itemWithLang = itemInLang.reduce((a, b) => [...a, ...b], [])
            // if (id) {
                // const atls = itemWithLang.map((a, i) => {
                //     let dat = { ...a }
                //     const zi = formik.values.attr.forEach((e) => {
                //         if (a.attributeId == e.attributeId && a.langId == e.languageId) {
                //             dat.value = e.value;
                //             dat.entityId = e.entityId
                //         }
                //         return dat
                //     })
                //     return dat
                // })
                // formik.setFieldValue('attr', atls)
            // } else {

            //     formik.setFieldValue('attr', itemWithLang)
            // }
            formik.setFieldValue('attr', itemWithLang)
            return itemWithLang
        });
        return await data
    }
    useEffect(() => {
        get_language_list()
        get_entity_type_list()
    }, [])
    useEffect(() => {
        if (id && languagelist) {
            get_entity_detail(id)
            console.log('neden1')
        } else {
            console.log('neden')
        }
    }, [languagelist, id]);
    function entityTypeChange(e, setFieldValue) {
        get_attribute_list(e)
        // setFieldValue('entitytypeId', e)
    }
    // useEffect(() => {
    //     setFieldValue('entitylabeltranslations', languagelist)
    // }, [
    //     languagelist
    // ])
    function createUser(data) {
        return service.save(data)
            .then(() => {
                toast.success('Ekleme başarılı.')
                history.push('.');
            })
            .catch((err) => {
                toast.error('Ekleme başarısız.')
            });
    }

    function updateUser(id, data) {
        return service.update(data, id)
            .then(() => {
                toast.success('Güncelleme başarılı.')
                history.push('..');
            })
            .catch((err) => {
                toast.error('Güncelleme başarısız.')
            });
    }
    const formik = useFormik({
        initialValues: {
            name: "",
            entitytypeId: '',
            entitylabeltranslations: [{ id: "", value: '' }],
            attr: false
        },
        onSubmit: values => {
            console.log(values)
            alert(JSON.stringify(values, null, 2));
            if (isAddMode) { createUser(values) } else {
                updateUser(id, values);
            }
        },
        // validationSchema: validationSchema,
        validateOnChange: false
    });
    return (
        <Container maxWidth="lg">

            <FormikProvider value={formik}>
                <Box component="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={6}>
                                <FormGroup column>
                                    <FormLabel>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            Ürün {!isAddMode ? "Düzenle" : "Ekle"}
                                        </Typography>
                                    </FormLabel>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={6}>
                                <FormGroup column>
                                    <TextField label="Ürün Adı" id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup column>
                                    <FormControl variant="outlined">
                                        <InputLabel id="demo-simple-select-label">Varlık Türü</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            label="Varlık Türü"
                                            name="entitytypeId"
                                            value={formik.values.entitytypeId}
                                            onChange={(e) => {
                                                entityTypeChange(e.target.value)
                                                formik.handleChange(e)
                                            }}
                                        >
                                            {entityTypeList.map((person) => (
                                                <MenuItem key={person.id} value={person.id}>
                                                    {person.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>


                        </Grid>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <FormGroup column>
                                    <FormLabel>
                                        <Typography variant="h6" component="div" gutterBottom>
                                            Ürün Adı Yabancı Dil Karşılıkları {!isAddMode ? "Düzenle" : "Ekle"}
                                        </Typography>
                                    </FormLabel>
                                </FormGroup>
                            </Grid>
                            <FieldArray name="entitylabeltranslations"
                                render={(arrayHelpers) => (
                                    formik.values.entitylabeltranslations.map((lang, index) => (
                                        <Grid item xs={12} md={6}>
                                            <FormGroup column>
                                                <TextField
                                                    label={`${lang.name && lang.name} Ürün Adı Karşılığı`}
                                                    key={index}
                                                    name={`entitylabeltranslations[${index}].value`}
                                                    value={formik.values.entitylabeltranslations[index].value || ''}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    ))
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>

                        </Grid>

                        {formik.values.attr && <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <FormGroup column>
                                    <FormLabel>
                                        <Typography variant="h6" component="div" gutterBottom>

                                            Ürün Özellikleri Yabancı Dil Karşılıkları {!isAddMode ? "Düzenle" : "Ekle"}
                                        </Typography>
                                    </FormLabel>
                                </FormGroup>
                            </Grid> <FieldArray name="attr"
                                render={(arrayHelpers) => (
                                    formik.values.attr.map((lang, index) => (
                                        <Grid item xs={12} md={6}>
                                            <FormGroup column>
                                                <TextField
                                                    label={`${lang.langName}-${lang.attribute.name} Ürün Özellik Karşılığı`}
                                                    key={index}
                                                    name={`attr[${index}].value`}
                                                    value={formik.values.attr[index].value || ''}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    ))
                                )}
                            /> </Grid>}

                        <Grid item xs={12} md={12}>
                            <FormGroup column>
                                <Button type="submit" variant="contained">submit</Button>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
            </FormikProvider >
            {/* </Formik> */}
            < pre > {JSON.stringify(formik.values, null, 2)}</pre >
            <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
        </Container >
    );
};

export default AddEdit;