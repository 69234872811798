import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './styles/style.scss'
import { ToastContainer } from 'react-toastify';
import UserStore from './stores/userContextV2';
import { StyledEngineProvider } from '@mui/material/styles';


ReactDOM.render(<UserStore><StyledEngineProvider injectFirst><ToastContainer/><App /> </StyledEngineProvider></UserStore>, document.getElementById('root'));
