import React, { useContext } from 'react';

import { HomeMaxOutlined } from '@mui/icons-material'
import { UserContext } from '../../stores/userContextV2'
import { logoutUser, loginUser } from '../../actions/userActions'

import { Container, Stack, Button, Typography } from '@mui/material'
function Home() {
    const { userState, userDispatch } = useContext(UserContext);
    console.log(UserContext)
    return (
        <Container>
            {userState ? <React.Fragment>
                <div className="baslik">
                    <Typography variant="h6" color="textPrimary" gutterBottom>Web Sayfası Yönetim Paneli</Typography>
                </div>
                <Typography>Sol kısımdaki açılabilir panelden ilgili bölümlere tıklayarak web sayfanız üzerinde değişiklik yapabilirsiniz.</Typography>
                <Typography>Yönetim Panelinin özellikleri: </Typography>
                <ul>
                    <li>Sayfa oluşturma,silme ve düzenleme</li>
                    {/* <li>Slider üzerindeki başlık ve maddeleri düzenleme</li>
                            <li>Medical Specials bölümündeki başlıkları düzenleme,başlık altındaki resimli içerikleri değiştirme,silme,link verme işlemi</li>
                            <li>Why Choose Unimed Alanına madde ekleme çıkarma silme </li>
                            <li>How Its maddeleri ekleme,silme, düzenleme</li> */}
                    <li>Analytics bölümünde google gibi site istatistiği tutan servislerin aktivasyonu sağlanabilir.</li>
                    <li>Platforms kısmı sayfanın altına whatsapp gibi chat uygulamaları eklemeyi sağlar.</li>
                </ul>
            </React.Fragment> : <Typography>Oturum Açın</Typography>}
        </Container>


    )
}

export default {
    routeProps: {
        path: '/',
        exact: true,
        component: Home
    },
    name: 'Home',
    icon: <HomeMaxOutlined fontSize="small"/>,
    headerLocale: true,
    hideMenu: true,
}