import React from 'react';
import PropTypes from 'prop-types';
import List from './list'
import { _analyticslistesi, _analyticsDetay, _analyticsGuncelle, _analyticsKaydet, _analyticsSil } from './services'
import { Route, Switch } from 'react-router-dom';
import { AddEdit } from './AddEdit'
import { Grid, Box } from '@mui/material'
import { GTranslate } from '@mui/icons-material';
const Analytics = function (props, { path }) {
    return <Switch>
        <Route exact path={`${props.path}`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={3} xl={3}>
                    <List
                        baslik={"Diller"}
                        path={props.path}
                    />
                </Grid>
                <Grid item sm={12} md={9} xl={9}>
                    <Box p={5}>
                        <p>Bu bölümden siteye ek dil eklenebilir.
                            <i>"Ekle"</i> butonundan yeni bir dil ekleyerek yönetim ve ziyaretçilerin gördüğü sayfalardaki dil kolonlarını oluşturup aktif veya deaktif edebilirsiniz.</p>
                        <article>Eklenmesi gereken üç alan bulunmaktadır.Bunlar sırasıyla kode,görünen isim,ve durum alanlarıdır.</article>
                        <p>Kod:Hem sitede hem yönetim panelinde görünen dilin kısa kodudur.Evrensel dil kodlarını kullanmak önemlidir.</p>
                        <p>Görünen İsim:Ziyaretçi sitede dili değiştirmek istediğinde açılır menude gördüğü seçeneklerdir.Dikkatli yazılmalıdır.</p>
                        <p>Durum:Önceden girilmiş ama artık ihtiyaç duyulmayan herhangi bir dili pasif hale getirip ziyaretçilerin görmemesini sağlayabilirsiniz.</p>
                        <h5>Örneğin:</h5>
                        <ul>
                            <li>Kod : en</li>
                            <li>Görünen İsim : English</li>
                            <li>Durum : Tikli</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/add`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/edit/:id`}>
            <AddEdit />
        </Route>
    </Switch >
}
Analytics.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/language',
        component: Analytics
    },
    name: 'Diller',
    icon: <GTranslate fontSize="small" />,
    hideMenu: false,
    manage: false,
    listCategory: 1
}