import React, { createContext, useEffect, useState } from "react";
import {_analyticslistesi} from './services'
export const LangContext = createContext();

// This context provider is passed to any component requiring the context
export const LangProvider = ({ children }) => {
  const [list, setList] = useState(false);
  useEffect(()=>{
    _analyticslistesi().then((res)=>{
      setList(res.data.data)
    })
  },[])
  return (
    <LangContext.Provider
      value={{
        list,
        setList,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
