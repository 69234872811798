import React, { useContext, useState } from 'react';
import { UserContext } from '../../stores/userContextV2'
import { loginUser, logoutUser } from '../../actions/userActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { LoginSC, LoginInputWrapperSC } from './styles';
import service from './services'
import { Container, Box, Grid, Link, Avatar, Typography, TextField, FormControlLabel, Checkbox, Button } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
export default (props) => {
    const { userDispatch } = useContext(UserContext);
    const [username, setUsername] = useState(false)
    const [password, setPassword] = useState(false)
    function login(event) {
        event.preventDefault();
        service._login(username, password)
            .then(function (res) {
                if (res.data.login) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                    userDispatch(loginUser())
                    toast.success('Oturum açıldı')
                } else {
                    localStorage.removeItem("user");
                    userDispatch(logoutUser())
                    toast.error('Kullanıcı adı ve şifrenizi kontrol ediniz.')
                }
            });
    }
    return <Container component="main" maxWidth="xs">
        <ToastContainer />
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Oturum Aç
            </Typography>
            <Box component="form" onSubmit={login} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Kullanıcı Adı"
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                    // autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Şifre"
                    type="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Hatırla"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Oturum Aç
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Şifremi unuttum?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">
                            {"Hesabın yoksa? Kayıt Ol"}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Container>
}