import React from 'react';
import PropTypes from 'prop-types';
import List from './list'
import { _analyticslistesi, _analyticsDetay, _analyticsGuncelle, _analyticsKaydet, _analyticsSil } from './services'
import { Route, Switch } from 'react-router-dom';
import { AddEdit } from './AddEdit'
import { Grid, Box } from '@mui/material'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
const Analytics = function (props) {

    return <Switch>
        <Route exact path={`${props.path}`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={3} xl={3}>
                    <List
                        baslik={"Kategori Özellik Grupları"}
                        path={props.path}
                    />
                </Grid>
                <Grid item sm={12} md={9} xl={9}>
                    <Box p={5}>
                        <p>
                            Bu bölümde sisteme varlık türü tanımlaması yapılır.
                        yani sisteminiz bir hotel rezervasyon sistemi ise hotel varlık türü buradan eklenir.Daha sonra tanımlanmış olan varlık türleri seçilerek bir varlık eklenebilir.Örneğin hotel varlık türü kaydedildiğinde artık sisteme hotel tanımları yapabilirsiniz.Titanik golf resort gibi. 
                        </p>
                    </Box>
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/add`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/edit/:id`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
    </Switch >
}
Analytics.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/entitytypeattributegroup',
        component: Analytics
    },
    name: 'Kategori Özellik Grup',
    icon: <AutoAwesomeMotionIcon fontSize="small"/>,
    hideMenu: false,
    manage: false,
    listCategory:1
}