import React from 'react';
import PropTypes from 'prop-types';
import List from './list'
import { _analyticslistesi, _analyticsDetay, _analyticsGuncelle, _analyticsKaydet, _analyticsSil } from './services'
import { Route, Switch } from 'react-router-dom';
import { AddEdit } from './AddEdit'
import { Grid, Box } from '@mui/material'
import { Assessment } from '@mui/icons-material';

const Analytics = function (props) {

    return <Switch>
        <Route exact path={`${props.path}`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={3} xl={3}>
                    <List
                        baslik={"Analiz Sağlayıcıları"}
                        path={props.path}
                    />
                </Grid>
                <Grid item sm={12} md={9} xl={9}>
                    <Box p={5}>
                        <p>Bu bölümden siteye ek dil eklenebilir.
                         <i>"Ekle"</i> butonundan yeni bir dil ekleyerek yönetim ve ziyaretçilerin gördüğü sayfalardaki dil kolonlarını oluşturup aktif veya deaktif edebilirsiniz.</p>
                        <article>Eklenmesi gereken üç alan bulunmaktadır.Bunlar sırasıyla code,name,ve durum alanlarıdır.</article>
                        <p>Code:Dilin kısaltmasıdır.</p>
                        <p>Name:Dilin ziyaretçi sayfasından değişimini yaparken görünen alandır.</p>
                        <p>Durum:Önceden girilmiş ama artık ihtiyaç duyulmayan herhangi bir dili pasif hale getirip ziyaretçilerin görmemesini sağlayabilirsiniz.</p>
                        <h5>Örneğin:</h5>
                        <ul>
                            <li>Code : en</li>
                            <li>Name : English</li>
                            <li>Durum : Tikli</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/add`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/edit/:id`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} sm={12} md={4} xl={4}>
                    <AddEdit />
                </Grid>
            </Grid>
        </Route>
    </Switch >
}
Analytics.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/analytics',
        component: Analytics
    },
    name: 'Analiz',
    icon: <Assessment fontSize="small"/>,
    hideMenu: false,
    manage: true
}