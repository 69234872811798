import React, { useState, useEffect } from 'react';
import Modal from "../../components/Modal";
import Lister from '../../components/List/'
import { IoIosAdd, IoMdRefreshCircle, IoMdSave } from "react-icons/io";
import { FaHashtag } from 'react-icons/fa';
import { Sag } from './styles'
import { toast } from 'react-toastify';
function MetaTags(props) {
    const [secim, setSecim] = useState(false);
    const [dataBaslik, setDataBaslik] = useState(false);
    const [liste, setListe] = useState(false);
    const [show, setShow] = useState(false);
    const [apiEndpoint] = useState("meta");
    useEffect(() => {
        fetch(props.apiUrl + `/${apiEndpoint}`)
            .then(res => {
                let routelist = res.json();
                return routelist;
            })
            .then(list => {
                setDataBaslik(list.results)
            })

        fetch(props.apiUrl + '/getlinks')
            .then(res => {
                let routelist = res.json();
                return routelist;
            })
            .then(list => {
                setListe(list);
            })
    }, [apiEndpoint,props.apiUrl])
    function showModal(e) {
        setShow(!show);
    };
    function showModalAndClear(e) {
        setSecim(false)
        setShow(!show);
    };
    function getir() {
        fetch(props.apiUrl + `/${apiEndpoint}`)
            .then(res => {
                let routelist = res.json();
                return routelist;
            })
            .then(list => {
                setDataBaslik(list.results);
            })
    }

 
    function _contentUpdateSliderItem() {

        const { id, name, trackedvalue, pageId } = secim;

        fetch(props.apiUrl + `/${apiEndpoint}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id,
                data: {
                    name, trackedvalue, pageId
                }
            })
        })

            .then(function (data) {

                if (!data.error) {
                    toast.success('Güncelleme başarılı.')
                    getir();
                } else {
                    toast.error('Güncelleme yapılamadı.')
                }
            });

    }
    function _contentUpdateSliderItemClear() {
        setSecim(false)
    }

    function _handleSec(e) {
        let secimProp = { ...secim }
        let alan = `${e.target.name}`;
        secimProp[alan] = e.target.value;
        setSecim(secimProp)
    }
    function _clickEvent(e) {
        fetch(props.apiUrl + `/${apiEndpoint}/${e.name}`).then(response => response.json())
            .then((gelen) => {
                setSecim(gelen.data)
            })
        showModal(e);
    };

function _contentSave() {
    fetch(props.apiUrl + `/${apiEndpoint}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                name: secim.name,
                trackedvalue: secim.trackedvalue,
                pageId: secim.pageId
            }
        })
    })
        .then(response => response.json())
        .then(function (data) {
            if (!data.error) {
                toast.success('Kaydetme başarılı.')
                getir();
            } else {
                toast.error('Kaydetme yapılamadı.')
            }
        });


}
function _clickDel(x) {
    fetch(props.apiUrl + `/${apiEndpoint}/${x}`, {
        crossDomain: true,
        xhrFields: {
            withCredentials: false,
        },
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    })
    .then(response => response.json())
    .then(function (data) {
        if (!data.error) {
            toast.success('Silme başarılı.')
            getir();
        } else {
            toast.error('Silme yapılamadı.')
        }
    });

}
return <div className="tasiyici">
    <Modal onClose={showModal} show={show}>
        <div className="inputs">
            <div className="editorName">
                <div className="checktasiyici" style={{ float: "none", margin: "0" }}>
                    <label>
                        Hangi Sayfa İçin:</label>
                    <select value={secim.pageId || ""} name="pageId" onChange={e => _handleSec(e)}>
                        <option value="secilmedi">Seçilmedi</option>
                        {liste ? liste.map((x, i) => {
                            return <option key={i} value={x.id}>{x.nameen}</option>
                        }) : false}
                    </select>
                </div>
                <input type="textbox" placeholder="Tag Name Örn:Robots" name="name" value={secim.name || ""} onChange={(e) => _handleSec(e)} />
                <input type="textbox" placeholder="Content Örn: Index" name="trackedvalue" value={secim.trackedvalue || ""} onChange={(e) => _handleSec(e)} />

            </div>

            <div className="editorName">
                <button disabled={(secim && !secim.id) ? false : true} onClick={_contentSave}><IoMdSave />Kaydet</button>
                <button disabled={secim.id ? false : true} onClick={_contentUpdateSliderItem}><IoMdRefreshCircle />Güncelle</button>
                <button disabled={secim ? false : true} onClick={_contentUpdateSliderItemClear}> <IoIosAdd />Yeni Oluştur</button>
            </div>
        </div>


    </Modal >
    <div className="sol">
        <div className="liste">
            {dataBaslik ? <Lister
                filter={""}
                listeBaslik={"Meta Etiketleri"}
                dataBaslik={dataBaslik}
                showModalAndClear={showModalAndClear}
                _clickDel={_clickDel}
                _clickEvent={_clickEvent}
                visual={"name"}
            /> : "Yükleniyor."}
        </div>
    </div>
    <Sag>
        <p>Sitenizin arama motorlarına hangi verileri sağlayacağını sayfa bazlı belirtebileceğiniz yönetim bölümündesiniz.Sayfa indexleme ve seo ile alakalı taglar burada belirlenir.</p>
        <h5>Örneğin:</h5>
        <ul>
            <li>Robots</li>
            <li>Schema.org</li>
        </ul>
    </Sag>
</div >
}


export default {
    routeProps: {
        path: '/metatags',
        component: MetaTags,
    },
    name: 'MetaTags',
    icon: <FaHashtag fontSize="small"/>,
    hideMenu:false,
};