import styled from 'styled-components';

export const ModalSC = styled.div`
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100vh;
    background: rgba(24, 24, 24, 0.322);
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    position: absolute;
    z-index: 200;
    padding: 50px 200px;
    top: 0;
    left: 0;
    .modalwrapper{
      z-index: 300;
      position: relative;
      width: 100;
      height: auto;
      background-color: white;
      padding: 20px;
      box-sizing: border-box;
      h2 {
        border-bottom: 1px solid #ccc;
        display: inline;
        //padding: 1rem;
        margin: 0;
        display: inline-block;
        border-bottom: 4px solid $bg-color-1;
        padding-bottom: 2px;
      }
      svg{
        float: right;
        width: 20px;
        height: 20px;
      }
      .content {
        padding: 20px;
        transition: 1.1s ease-out;
        .editorName,fieldset {
          //width: 100%;
          flex-grow: 1;
          padding: 20px;
          box-sizing: border-box;
          background-color: $bg-color-hover-1;
          margin:10px 10px;
          border-radius: 6px;
          border:none;
          transition: 300ms;
          &:disabled{
              opacity: 0.5;
             filter: grayscale(100);
             transition: 300ms;
          }
          h4{
              font-size:14px;
              font-weight: normal;

          }
          button{
            padding: 10px 15px;
            background-color: $bg-color-1;
            border:none;
            border-radius: 4px;
            color:$bg-color-hover-1;
            box-sizing: border-box;
            transition: 300ms;
            line-height:22px;
            margin:0px 20px 0px 0px;
            svg{
              float: left;
              margin-left: 10px;
            }
            &:last-child{
                background-color: $bg-color-orange;
            }
        
            &:disabled{
                transition: 300ms;
                background-color: $bg-color-2;
                &:hover{
                    background-color: $bg-color-2;
                    box-shadow: none;
                }
            }
            &:hover{
                transition: 300ms;
                background-color: $bg-color-orange;
            }
          }
          .checktasiyici{
              width:100%;
              background: $bg-color-2;
              border-left: 2px solid $bg-color-1;
              margin: 10px 0px;
              border-radius: 0px 6px 6px 0px;
              padding: 20px;
              box-sizing: border-box;
              transition: 500ms;
              label{
                  margin:0px 10px;
              }
              select{
                  padding: 10px;
                  border-radius: 6px;
                  border-color: $bg-color-1;
                  &:focus{
                      outline: none;
                  }
                  &:hover{
                      border-color:$bg-color-orange;
                  }
              }
              &:hover {
                  //border: 2px solid $bg-color-1;
                  
                  transition: 500ms;
                -webkit-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
                -moz-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
                box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
                }
          }
          input {
            caret-color:$bg-color-1;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            margin:10px 0px;
            flex-grow: 1;
            //border: 2px solid rgba(0, 0, 0, 0.123);
            border-radius: 0px 6px 6px 0px;
            border: none;
            transition: 500ms;
            border-left:2px solid $bg-color-1;
            background-color: $bg-color-2;
            -webkit-box-shadow: outset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: outset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
            box-shadow: outset -20px 0px 35px -21px rgba(0, 0, 0, 0.75);
            &:focus {
              //border: 2px solid $bg-color-1;
              outline: none;
              transition: 500ms;
            -webkit-box-shadow: 0.2rem 0.8rem 1.6rem rgba(6, 162, 136, 0.479);
            -moz-box-shadow: 0.2rem 0.8rem 1.6rem rgba(6, 162, 135,0.479);
            box-shadow: 0.2rem 0.8rem 1.6rem rgba(6, 162, 135, 0.479);
            }
            &:hover {
              //border: 2px solid $bg-color-1;
              outline: none;
              transition: 500ms;
            -webkit-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
            -moz-box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
            box-shadow: 0.2rem 0.8rem 1.6rem rgba(0, 0, 0, 0.13);
            }
          }
          label {
            width: auto;
            font-size: 14px;
          }
          input[type="checkbox"] {
            width: auto;
            box-shadow: none;
          }
          select {
            // flex-grow: 1;
            //width: 100% ;
            width: 100px;
            option {
              // flex-grow: 1;
            }
          }
        }
      }
      .actions {
        border-top: 1px solid #ccc;
        background: #eee;
        padding: 0.5rem 1rem;
        button {
          border: 0;
          background: $bg-color-orange;
          border-radius: 5px;
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
          line-height: 1;
          color:$bg-color-2;
        }
      }
    }
  .modal.off {
    opacity: 0;
    visibility: hidden;
    filter: blur(8px);
    transform: scale(0.33);
    box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
    transition: 1.1s ease-out;
  }
`