import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
const service = {
    sort: () => {
        return axios.get(API_URL + '/analytics', { headers: authHeader() })
    },
    detail: (id) => {
        return axios.get(API_URL + `/analytics/${id}`, { headers: authHeader() })
    },
    delete: (id) => {
        return axios.delete(API_URL + `/analytics/${id}`, { headers: authHeader() })
    },
    save: (data) => {
        const params = {
            name: data.name,
            trackedvalue: data.trackedvalue
        }
        return axios.post(API_URL + `/analytics`, { ...params }, { headers: authHeader() })
    },
    update: (data, id) => {
        return axios.put(API_URL + `/analytics/${id}`, { ...data }, { headers: authHeader() })
    }
}
export default service
