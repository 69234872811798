import React, { useEffect, useState, useMemo } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom'
import { Button, Box, TextField, Switch, FormGroup, FormLabel, FormHelperText, FormControlLabel, FormControl, MenuItem, InputLabel, Select } from '@mui/material';

import service from './services'
import { useHistory } from "react-router-dom";
import Transferlist from './transferlist'
// import { FormControl,FormGroup } from '@mui/material';
function AddEdit() {
    // const { id } = match.params;
    let history = useHistory();
    let { id } = useParams();
    const isAddMode = !id;
    const [user, setUser] = useState({});
    const [languagelist, setLanguagelist] = useState(false);
    useEffect(() => {
        let langs;
        service.attributeList().then(incoming => {
            console.log(incoming.data.data)
            langs = incoming.data.data
            setLanguagelist(incoming.data.data)
            if (!isAddMode) {
                service.detail(id).then(user => {
                    console.log(user)

                    let gelenData = user.data.data
             
                    var filteredArray = langs.filter(function (array_el) {
                        return gelenData.attributegroupitems.filter(function (anotherOne_el) {
                            return anotherOne_el.attributeId == array_el.id;
                        })
                        .length == 0
                    });
                    var filteredArray2 = langs.filter(function (array_el) {
                        return gelenData.attributegroupitems.filter(function (anotherOne_el) {
                            return anotherOne_el.attributeId == array_el.id;
                        })
                        .length ==1
                    });
                    let allData = {
                        name: user.data.data.name,
                        'transferlist': filteredArray2
                    }
                    setLanguagelist(filteredArray)
                    const fields = ['name', 'transferlist'];

                    // fields.forEach(field => setValue(field, user.data.data[field]));
                    // const rec = langs.map((t, s) => {
                    //     let obj = t
                    //     gelenData.entitytypelabeltranslations.map((k, l) => {
                    //         if (obj.id == k.languageId) {
                    //             // obj.id = k.id
                    //             // obj.id = t.id
                    //             obj.value = k.value
                    //             obj.labeltranslationId = k.id
                    //         }
                    //     })
                    //     return obj
                    // })
                    // let dongu = { name: gelenData.name, entitytypelabeltranslations: rec }
                    console.log(allData)
                    fields.map((item, i) => {
                        setValue(item, allData[item])
                    })
                    // 
                    setUser(user);
                });
            }
        });

    }, []);
    const { transform, control, register, handleSubmit, watch, getValues, setValue, reset, formState: { errors } } = useForm({
        // defaultValues: {
        //     entitytypelabeltranslations: useMemo(() => {
        //         console.log("User has changed");
        //         console.log(languagelist)
        //         return [languagelist];
        //     }, [languagelist])
        // }
    });
    useEffect(() => {
        if (isAddMode) {
            console.log("Reset");
            // reset({ entitytypelabeltranslations: languagelist });
        }
        // reset({ entitytypelabeltranslations: languagelist });
    }, [languagelist]);

    // const { fields, append } = useFieldArray({
    //     control,
    //     name: "entitytypelabeltranslations"
    // });

    function onSubmit(data) {
        console.log(data)
        return isAddMode
            ? createUser(data)
            : updateUser(id, data);
    }

    function createUser(data) {
        return service.save(data)
            .then(() => {
                toast.success('Ekleme başarılı.')
                history.push('.');
            })
            .catch((err) => {
                toast.error('Ekleme başarısız.')
                console.log(err)
            });
    }

    function updateUser(id, data) {
        return service.update(data, id)
            .then(() => {
                toast.success('Güncelleme başarılı.')
                history.push('..');
            })
            .catch((err) => {
                toast.error('Güncelleme başarısız.')
                console.log(err)
            });
    }
    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <FormControl>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormLabel>Özellik {!isAddMode ? "Düzenle" : "Ekle"}</FormLabel>
                <FormGroup column>
                    <Controller
                        render={({ field }) => <TextField {...field} label="Tanımlayıcı İsim" margin="normal" variant="outlined" />}
                        control={control}
                        name="name"
                        defaultValue=""
                    />
                </FormGroup>
                {/* {fields && fields.map((x, i) => {
                    console.log(x)
                    // if (x.active) {
                    //     { isAddMode && setValue(`entitytypelabeltranslations.${i}.id`, x.id) }
                    let domObject = <FormGroup column key={i}>
                        <Controller
                            render={({ field }) => <TextField {...field} disabled={!x.active} label={x.name + " Karşılığı"} margin="normal" variant="outlined" />}
                            control={control}
                            name={`entitytypelabeltranslations.${i}.value`}
                            defaultValue=""
                        />
                    </FormGroup>
                    return domObject

                })} */}
                <FormGroup>
                    {/* <Transferlist props/> */}
                    <Transferlist
                        setValue={setValue}
                        getValues={getValues("transferlist")}
                        allist={languagelist}
                        {...register("transferlist")} />
                    {/* <Controller
                        render={({ field }) =>
                            <Transferlist {...field}
                                onLeftClick={}
                                label="Tanımlayıcı İsim"
                                margin="normal"
                                variant="outlined"
                            />}
                        control={control}
                        name="transferlist"
                        defaultValue=""
                    /> */}
                </FormGroup>
                <FormGroup row >
                    <Box m={1} ml={0}>
                        <Button variant="contained" color="primary" type="submit">
                            Kaydet
                        </Button>
                    </Box>
                    <Box m={1}>
                        <Button component={Link} variant="contained" color="secondary" to={isAddMode ? '.' : '..'}>
                            İptal
                        </Button>
                    </Box>
                </FormGroup>
                {errors.code && <span>This field is required</span>}
            </form >
        </FormControl >
    );
}
export { AddEdit }