import Analytics from './Analytics';
import EditorWys2 from './EditorWys2';
import Home from './Home';
// import HowIts from './HowIts';
// import MedicalSpecials from './MedicalSpecials';
import MetaTags from './MetaTags';
// import PageCreate from './PageCreate';
import Platforms from './Platforms';
import Test from './Test';
import Version from './Version';
// import WhyChoose from './WhyChoose';
import Gallery from './Gallery'
// import Hotel from './Hotel'
import HotelV2 from './HotelV2'
import Paket from './Paket'
import Saha from './Saha'
import MultiSlider from './MultiSlider'
import Mail from './Mail'
import Push from './PushNotification'
import Talep from './Talep'
import Language from './Language'
import EntityType from './EntityType'
import AttributeType from './AttributeType'
import Attribute from './Attribute'
import Entity from './Entity'
import AttributeGroup from './AttributeGroup'
import AttributeGroupItems from './AttributeGroupItems'
import EntityTypeAttributeGroup from './EntityTypeAttributeGroup'
import Tables from './Tables'
import User from './User'
export default [
    Paket,
    HotelV2,
    Saha,
    Gallery,
    MultiSlider,
    // EditorWys2,
    Talep,
    Analytics,
    Home,
    // HowIts,
    // MedicalSpecials,
    // MetaTags,
    // PageCreate,
    Platforms,
    Test,
    Version,
    Mail,
    Push,
    Language,
    EntityType,
    Entity,
    AttributeType,
    Attribute,
    AttributeGroup,
    // AttributeGroupItems,
    EntityTypeAttributeGroup,
    Tables,
    User
    // WhyChoose,
];