import React from 'react'

export default function (props) {
    return <div className="inputs">
        <div className="editorName">
            <button
                disabled={props.secim.id ? false : true}
                onClick={props.update}
            >
                Güncelle
        </button>
            <button
                disabled={(props.secim && !props.secim.id) ? false : true}
                onClick={props.save}
            >
                Kaydet
            </button>
            <button
                disabled={props.secim ? false : true}
                onClick={props.ekle}>
                Temizle
                    </button>
        </div></div>
}