import styled from 'styled-components'

export const LogoSC = styled.div`
    /* padding: 20px; */
      box-sizing: border-box;
      // flex-basis: 10%;
      text-transform: capitalize;
      sub {
        font-size: 8px;
      }
`