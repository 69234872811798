import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
const endpoint = '/attribute'
const service = {
    sort: () => {
        return axios.get(API_URL + endpoint, { headers: authHeader() })
    },
    detail: (id) => {
        return axios.get(API_URL + endpoint + `/${id}`, { headers: authHeader() })
    },
    delete: (id) => {
        return axios.delete(API_URL + endpoint + `/${id}`, { headers: authHeader() })
    },
    save: (data) => {
        const params = {
            name: data.name
        }
        return axios.post(API_URL + endpoint, { ...data }, { headers: authHeader() })
    },
    update: (data, id) => {
        return axios.put(API_URL +  endpoint + `/${id}`, { ...data }, { headers: authHeader() })
    },
    entitytypeList: () => {
        return axios.get(API_URL + '/attributetype', { headers: authHeader() })
    },
    languageList: () => {
        return axios.get(API_URL + '/language', { headers: authHeader() })
    }
}
export default service
