import React, { useState } from 'react'
export default (props) => {
    const [checked, setChecked] = useState(props.data.set_bold)
    return <labeL style={{ textAlign: 'center' }}>Bold yap:<input
        defaultChecked={checked && 'checked'}
        value={checked}
        type="checkbox"
        onClick={(e) => {setChecked(!checked); props.addBold(props.index, props.secondindex, !checked, props.data)}}
        onChange={(e) => props.rowBoldChange(checked, props.data, props.columnid)}
    /></labeL>
}