import styled from 'styled-components'

export const TableHeader = styled.div`
 flex-basis:100%;
 display:flex;
 flex-direction:row;
 justify-content:flex-start;
 background:white;
 /* flex-wrap:wrap; */
 span{
     padding:10px;
     color:white;
     flex:1;
     box-sizing:border-box;
     word-break: break-all;
     text-align:center;
     display:flex;
     flex-direction:column;
     flex-wrap:wrap;
     h4{
         margin:0px;
         padding:0px;
         flex-basis:100;
     }
 }
`
export const TableColumn = styled.input`
background-color:none;
margin:0px;
padding:0px;
flex:1;
width:100%;
box-sizing:border-box;
`