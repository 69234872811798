import React from 'react';
import { TableBody } from './styles'
import SetBold from './setbold'
export default (props) => {
    return <TableBody>{props.columnNames ? props.columnNames.sort(function (a, b) { return a.sira_no - b.sira_no }).map((x, i) => {
        return <li key={i} style={{ backgroundColor: x.color }}>
            {x.rows && x.rows.length > 0 ? x.rows.sort(function (c, d) { return c.sira_no - d.sira_no }).map((y, z) => {
                return <><input key={z}
                    value={y.value|| ""} type="textbox"
                    onChange={(e) => props.addValue(i, z, e.target.value, y)}
                    onBlur={(e) => props.rowRemote(e.target.value, y, x.id)}
                />
                <SetBold
                data={y}
                index={i}
                secondindex={z}
                columnid={x.id}
                {...props}
                />
                </>
            }) : false}
        </li>
    }) : false}</TableBody>
}