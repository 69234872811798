import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes';
import Header from './components/Header'
import { UserContext } from './stores/userContextV2'
import PTFPN from './utils/pageTitleForProjectName';
import { LangProvider } from './stores/langContext';
import LoginComponent from './components/login'
import checkSession from './utils/checkSession'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/material/styles';
import Drawe from './components/drawer'
import { Box } from '@mui/material'
import GlobalStyle from './styles/global'

import { createTheme, ThemeProvider } from '@mui/material/styles';
import clsx from 'clsx';
const drawerWidth = 240;
// const theme = createTheme();
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function App() {
  const theme = useTheme();
  const { userState, userDispatch } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  useEffect(() => { PTFPN(); }); //sayfa başlığını package.json daki proje ismi sürüm adı ve version değerleri ile değiştiriyor.
  // useEffect(() => {

  checkSession(userDispatch) // token exp. date kontrolü yapıp exp olmuşsa  logine atıyor
  // },[userState,userDispatch]);
  // const classes = useStyles();
  const handleDrawerOpen = () => {
    console.log(open)
    setOpen(true);
  };
  const handleDrawerClose = () => {
    console.log(open)
    setOpen(false);
  };


  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <LangProvider>
      <ThemeProvider theme={theme}>
        <Router>
          {userState ? <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <GlobalStyle/>
            <Header open={open} opener={handleDrawerOpen} />
            <Drawe open={open} close={handleDrawerClose} />
            <Box component="main" 
            sx={{ flexGrow: 1, p: 3,bgcolor: 'background.default' }}
            >
              <DrawerHeader />
              <Routes />
            </Box>
          </Box> : <LoginComponent />
          }
        </Router>
      </ThemeProvider>
    </LangProvider>
  )
}
export default App;
