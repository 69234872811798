
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { PlaceHolderEn, PlaceHolderTr, PlaceHolderDe, PlaceHolderRu, PlaceHolderCz,PlaceHolderFr } from './placeHolder'
import { decorator } from './decorator'
const decoratorLocal = decorator;
const blocksFromHTMLEn = convertFromHTML(PlaceHolderEn);
export const stateEn = ContentState.createFromBlockArray(
    blocksFromHTMLEn.contentBlocks,
    blocksFromHTMLEn.entityMap,
);


const blocksFromHTMLRu = convertFromHTML(PlaceHolderRu);
export const stateRu = ContentState.createFromBlockArray(
    blocksFromHTMLRu.contentBlocks,
    blocksFromHTMLRu.entityMap,
);


const blocksFromHTMLDe = convertFromHTML(PlaceHolderDe);
export const stateDe = ContentState.createFromBlockArray(
    blocksFromHTMLDe.contentBlocks,
    blocksFromHTMLDe.entityMap,
);


const blocksFromHTMLTr = convertFromHTML(PlaceHolderTr);
export const stateTr = ContentState.createFromBlockArray(
    blocksFromHTMLTr.contentBlocks,
    blocksFromHTMLTr.entityMap,
);

const blocksFromHTMLCz = convertFromHTML(PlaceHolderCz);
export const stateCz = ContentState.createFromBlockArray(
    blocksFromHTMLCz.contentBlocks,
    blocksFromHTMLCz.entityMap,
);
const blocksFromHTMLFr = convertFromHTML(PlaceHolderFr);
export const stateFr = ContentState.createFromBlockArray(
    blocksFromHTMLFr.contentBlocks,
    blocksFromHTMLFr.entityMap,
);

export const initialStateTr = EditorState.createWithContent(stateTr, decoratorLocal);
export const initialStateEn = EditorState.createWithContent(stateEn, decoratorLocal);
export const initialStateRu = EditorState.createWithContent(stateRu, decoratorLocal);
export const initialStateDe = EditorState.createWithContent(stateDe, decoratorLocal);
export const initialStateCz = EditorState.createWithContent(stateCz, decoratorLocal);
export const initialStateFr = EditorState.createWithContent(stateFr, decoratorLocal);
