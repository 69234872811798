import React, { useState, useEffect, useContext } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../styles/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs';
import ListViewer from './Liste/';
import { FaQuestionCircle, FaPollH } from 'react-icons/fa';
import { initialStateCz, initialStateDe, initialStateEn, initialStateRu, initialStateTr } from './editorInitial/'
import { toast } from 'react-toastify';
import Editors from './editorInitial/editors'
import StepZilla from "react-stepzilla";
import MultiPrice from './multiprice'
import MultiSaha from './multisaha';
import MultiIcerik from './multiicerik';
import { _langlist, _paketlistesi, _paketGuncelle, _hotellistesi, _paketDetay, _paketSil, _paketKaydet } from './services'
import Baslik from './baslik'
import { LangContext } from '../../stores/langContext';
import BallotIcon from '@mui/icons-material/Ballot';
function Paket(props) {
  const { list } = useContext(LangContext);
  const [editorStateTr, setEditorStateTr] = useState(initialStateTr);
  const [editorStateEn, setEditorStateEn] = useState(initialStateEn);
  const [editorStateRu, setEditorStateRu] = useState(initialStateRu);
  const [editorStateDe, setEditorStateDe] = useState(initialStateDe);
  const [editorStateCz, setEditorStateCz] = useState(initialStateCz);
  const [name, setName] = useState(list);
  const [nameen, setNameen] = useState();
  const [rounds, setRounds] = useState();
  const [hotel_id, setHotelId] = useState(false);
  const [list_top, setListTop] = useState(false);
  const [list_sira_no, setListNo] = useState();
  const [secim, setSecim] = useState(false);
  const [liste, setListe] = useState(false);
  const [hotellist, setHotelList] = useState(false);
  const [courses, setCourse] = useState([]);
  const [prices, setPrices] = useState([]);
  const [icerik, setIcerik] = useState([]);
  const [art, setArt] = useState(1);
  const [artPrice, setArtPrice] = useState(1);
  const [artIcerik, setArtIcerik] = useState(1);
  const [diller, setDiller] = useState(false);
  useEffect(() => {
    _paketlistesi().then((res) => {
      setListe(res.data.data);
    });
    _hotellistesi().then((res) => {
      setHotelList(res.data.data);
    });
  }, [])
  useEffect(() => {
    console.log("res")
    _langlist().then((res) => {
      console.log(res)
      console.log("res")
      setDiller(res.data.data);
    });
  }, [])
  useEffect(() => {
    setName(list)
  }, [list])
  function _yeniSayfa() {
    setSecim(false)
    setName(false)
    setRounds(false)
    setListNo(false)
    setHotelId(false)
    setIcerik([])
    setPrices([])
    setCourse([])
    setEditorStateTr(initialStateTr)
    setEditorStateEn(initialStateEn)
    setEditorStateRu(initialStateRu)
    setEditorStateDe(initialStateDe)
    setEditorStateCz(initialStateCz)
  }
  async function _listeGuncelle() {
    await _paketlistesi().then((res) => {
      console.log(res)
      setListe(res.data.data);
    });
  }
  function _clickDel(x) {
    _paketSil(x.id)
      .then(function (data) {
        if (!data.data.error) {
          toast.success('Paket Silindi')
          setListe(false)
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Paket silme başarısız.')
        }
      });
  }
  function _contentSave() {
    _paketKaydet(
      {
        name: name,
        rounds: rounds,
        tr: draftToHtml(convertToRaw(editorStateTr.getCurrentContent())),
        en: draftToHtml(convertToRaw(editorStateEn.getCurrentContent())),
        ru: draftToHtml(convertToRaw(editorStateRu.getCurrentContent())),
        de: draftToHtml(convertToRaw(editorStateDe.getCurrentContent())),
        cz: draftToHtml(convertToRaw(editorStateCz.getCurrentContent())),
        prices: prices,
        sahas: courses,
        icerik: icerik,
        hotel_id: hotel_id,
        list_top: list_top,
        list_sira_no: list_sira_no
      }
    )
      .then(function (data) {
        if (!data.data.error) {
          toast.success('Paket Eklendi')
          console.log("zaa")
          setListe(false);
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Paket Eklenemedi')
        }
      });


  }
  function _stateConverter(data) {
    const blocksFromHtml = htmlToDraft(data);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  function _clickEvent(e) {
    _paketDetay(e.id).then((gelen) => {
      console.log(gelen)
      const editorStateTr = _stateConverter(gelen.data.data.aciklama[0].tr);
      const editorStateEn = _stateConverter(gelen.data.data.aciklama[0].en);
      const editorStateRu = _stateConverter(gelen.data.data.aciklama[0].ru);
      const editorStateDe = _stateConverter(gelen.data.data.aciklama[0].de);
      const editorStateCz = _stateConverter(gelen.data.data.aciklama[0].cz);

      setEditorStateTr(editorStateTr)
      setEditorStateRu(editorStateRu)
      setEditorStateDe(editorStateDe)
      setEditorStateEn(editorStateEn)
      setEditorStateCz(editorStateCz)
      setCourse(gelen.data.sahalar)
      setPrices(gelen.data.data.prices)
      setIcerik(gelen.data.data.icerik)
      setSecim(gelen.data.data)
      // setName(gelen.data.data.translations)
      let obj = {},
        result;
      gelen.data.data.translations.forEach(function (a) {
        obj[a.language_code] = a;
      });
      console.log(obj)
      result = name.map(function (a) {
        console.log(obj[a.code])
        if (obj[a.code] === undefined) {
          return {
            name: a.name,
            active: a.active,
            language_code: a.code,
          }
        } else {
          return {
            id: obj[a.code].id,
            name: a.name,
            active: a.active,
            value: obj[a.code].value,
            language_code: a.code,
            product_id: obj[a.code].product_id
          };
        }

      });
      console.log(result)
      setName(result)
      setRounds(gelen.data.data.rounds)
      setListTop(gelen.data.data.list_top)
      setListNo(gelen.data.data.list_sira_no)
      setHotelId(gelen.data.data.hotel[0] !== undefined ? gelen.data.data.hotel[0].id : false)
    });

  }
  function onEditorStateChangeEn(editorStateEn) {
    setEditorStateEn(editorStateEn)
  }
  function onEditorStateChangeRu(editorStateRu) {
    setEditorStateRu(editorStateRu)
  }
  function onEditorStateChangeDe(editorStateDe) {
    setEditorStateDe(editorStateDe)
  }
  function onEditorStateChangeTr(editorStateTr) {
    setEditorStateTr(editorStateTr)
  }
  function onEditorStateChangeCz(editorStateCz) {
    setEditorStateCz(editorStateCz)
  }

  function _contentUpdate() {

    const { id } = secim;
    let htmlen = draftToHtml(convertToRaw(editorStateEn.getCurrentContent()))
    let htmlru = draftToHtml(convertToRaw(editorStateRu.getCurrentContent()))
    let htmlde = draftToHtml(convertToRaw(editorStateDe.getCurrentContent()))
    let htmltr = draftToHtml(convertToRaw(editorStateTr.getCurrentContent()))
    let htmlCz = draftToHtml(convertToRaw(editorStateCz.getCurrentContent()))
    _paketGuncelle(
      {
        aciklama_id: secim.aciklama[0].id,
        name: name,
        rounds: rounds,
        tr: htmltr,
        en: htmlen,
        ru: htmlru,
        de: htmlde,
        cz: htmlCz,
        prices: prices,
        sahas: courses,
        icerik: icerik,
        hotel_id: hotel_id,
        list_top: list_top,
        list_sira_no: list_sira_no
      }
      , id)
      .then(function (res) {
        if (!res.data.error) {
          toast.success('Paket Güncellendi')
          setListe(false)
          _listeGuncelle();
          _yeniSayfa();
        } else {
          toast.error('Paket güncelleme başarısız.')
        }
      });

  }


  function _onChangeCourse(e, i) {
    let arr = courses;
    arr[i] = { ...arr[i], [e.target.name]: e.target.value }
    // setCourse(prevState => [...prevState, {[e.target.name]:e.target.value}])
    setCourse([...arr])
  }
  function _onDeleteCourse(e, i) {
    let res = courses;
    res.splice(i, 1);
    setArt(res.length)
    setCourse([...res])
  }
  function _onChangePrices(name, e, i) {
    // console.log(e.target.name)
    // console.log(e.target.value)
    // // setCourse(prevState => [...prevState, {[e.target.name]:e.target.value}])
    // setPrices({ ...prices, [i]: { ...prices[i], [e.target.name]: e.target.value } })
    let arr = prices;
    arr[i] = { ...arr[i], [name]: e }
    // setCourse(prevState => [...prevState, {[e.target.name]:e.target.value}])
    setPrices([...arr])
  }
  function _onDeletePrices(e, i) {
    let res = prices;
    res.splice(i, 1);
    setArtPrice(res.length)
    setPrices([...res])
  }
  function _onChangeIcerik(e, i) {
    console.log(e.target.name)
    console.log(e.target.value)
    let arr = icerik;
    arr[i] = { ...arr[i], [e.target.name]: e.target.value }
    // setCourse(prevState => [...prevState, {[e.target.name]:e.target.value}])
    setIcerik([...arr])
  }
  function _onDeleteIcerik(e, i) {
    let res = icerik;
    res.splice(i, 1);
    setArtIcerik(res.length)
    setIcerik([...res])
  }
  function _changeName(e, i) {
    // { ...arr[i], [e.target.name]: e.target.value }
    // setCourse(prevState => [...prevState, {[e.target.name]:e.target.value}])
    // setCourse({ ...arr[i], [e.target.name]: e.target.value })
    console.log(name)
    let arr = [...name];
    arr[i]['language_code'] = e.target.name;
    arr[i]['value'] = e.target.value;
    // arr[i] = { ...arr[i], language_code: e.target.name, value: e.target.value }
    // setName([...name,{type:e.target.name,value:e.target.value}])
    setName(arr)
    console.log(name)
  }
  const steps = [
    {
      name: 'Ad ve Hotel', component: <div className="inputs">
        <div className="editorName">
          <label>Paket Adı</label>
          <Baslik
            data={name}
            sabit={true}
            list={name}
            // setDiller={setDiller} 
            change={_changeName} />
          <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
          <input
            type="text"
            required
            value={rounds || ""}
            name="rounds"
            placeholder="Rounds"
            onChange={(e) => setRounds(e.target.value)} />
          <input
            type="text"
            required
            value={list_sira_no || ""}
            name="list_sira_no"
            placeholder="Liste sıra no"
            onChange={(e) => setListNo(e.target.value)} />
          {/* <input type="checkbox" id="list_top" name="list_top" checked={list_top} onChange={(e) => setListTop(!list_top)} /> */}
          {/* <label for="list_top">İlk 12 de göster</label><br></br> */}

          <div className="checktasiyici">
            <label>Hotel Seç</label>
            <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
            {/* <input type="checkbox" required value={secim.single_price || ""} name="single_price" placeholder="e.g:10000" onChange={(e) => this._inputChange(e)} /> */}
            <select value={hotel_id} onChange={(e) => setHotelId(e.target.value)} name="hotel_id">
              <option value={false}>"Seçilmedi"</option>
              {hotellist ? hotellist.map((x, i) => {
                return <option key={i} value={x.id}>{x.name}</option>
              }) : false}
            </select>
          </div>
        </div>
      </div>
    },
    {
      name: 'İçerik', component: <div className="inputs">
        <div className="editorName">
          <MultiIcerik
            apiUrl={props.apiUrl}
            values={icerik}
            change={_onChangeIcerik}
            delete={_onDeleteIcerik}
            art={artIcerik}
          />
          <button className="sahaekle" onClick={(e) => setIcerik([...icerik, []])}>İçerik ekle</button>

        </div>
      </div>
    },
    {
      name: 'Ücretler', component: <div className="inputs">
        <div className="editorName">
          <MultiPrice
            apiUrl={props.apiUrl}
            change={_onChangePrices}
            delete={_onDeletePrices}
            values={prices}
            art={artPrice}
          />
          <button className="sahaekle" onClick={(e) => setPrices([...prices, []])}>Fiyat ekle</button>


        </div>
      </div>
    },
    {
      name: 'Sahalar', component: <div className="inputs">
        <div className="editorName">
          <MultiSaha
            apiUrl={props.apiUrl}
            change={_onChangeCourse}
            delete={_onDeleteCourse}
            values={courses}
            art={art}
          />
          <button className="Dönem ekle" onClick={(e) => setCourse([...courses, []])}>Saha ekle</button>
        </div>
      </div>
    },
    {
      name: 'Açıklama',
      component: <Editors
        editorStateEn={editorStateEn}
        editorStateRu={editorStateRu}
        editorStateDe={editorStateDe}
        editorStateTr={editorStateTr}
        editorStateCz={editorStateCz}
        onEditorStateChangeEn={onEditorStateChangeEn}
        onEditorStateChangeRu={onEditorStateChangeRu}
        onEditorStateChangeDe={onEditorStateChangeDe}
        onEditorStateChangeTr={onEditorStateChangeTr}
        onEditorStateChangeCz={onEditorStateChangeCz}
      />
    },
    {
      name: 'Bitir', component: <div className="inputs">
        <div className="editorName">
          <button disabled={secim.id ? false : true} onClick={_contentUpdate}>Güncelle</button>
          <button disabled={(name && !secim.id) ? false : true} onClick={_contentSave}>Kaydet</button>
          <button disabled={secim ? false : true} onClick={_yeniSayfa}>Temizle</button>
        </div>
      </div>
    }
  ];
  return (
    <div className="tasiyici">
      <div className="sol">
        <div className="liste">
          {liste ? <ListViewer
            title="Golf Paketleri"
            liste={liste}
            ekle={_yeniSayfa}
            clickDel={_clickDel}
            clickEvent={_clickEvent} /> : <div className="loading">"Yükleniyor..."</div>}
        </div>
      </div>
      <div className="sag">
        <div className="baslik">
          <h4>Paket İçeriği</h4>
        </div>

        {/* {secim?<Multistep showNavigation={true} steps={steps} />:"Lütfen listeden bir otel seçin yada 'yeni'  butonuna basınız."}  */}
        <StepZilla
          steps={steps}
          preventEnterSubmission={true}
          nextButtonCls="ileri"
          backButtonCls='geri'
        />



      </div>

    </div>);
}


export default {
  routeProps: {
    path: '/paket',
    component: Paket
  },
  name: 'Paketler',
  icon: <BallotIcon fontSize="small"/>,
  hideMenu: true,
  listCategory:2,
}
