import React from 'react';
import { MdDeleteForever, MdModeEdit } from "react-icons/md";
import styled from 'styled-components'

const ListButtonSC =styled.button`
  background: none;
  border: none;
  float: right;
  cursor: pointer;
  &:last-child {
    color: $bg-color-1;
  }
  img {
    width: 10px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover{
    opacity: .8;
  }
`
export default function ListButtons(props){
    return(
        <React.Fragment>
            <ListButtonSC  onClick={() => props._clickDel(props.x.id)}>
                <MdDeleteForever />
                </ListButtonSC>
                <ListButtonSC onClick={() => props._clickEvent(props.x)}>
                    <MdModeEdit />
                </ListButtonSC>
                    </React.Fragment>
    )
}