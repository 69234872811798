import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;

export const _analyticslistesi = () => {
    return axios.get(API_URL + '/user', { headers: authHeader() })
}
export const _analyticsDetay = (id) => {
    return axios.get(API_URL + `/user/${id}`, { headers: authHeader() })
}
export const _analyticsSil = (id) => {
    return axios.delete(API_URL + `/user/${id}`, { headers: authHeader() })
}
export const _analyticsKaydet = (data) => {
    const params = {
        name: data.name,
        surname: data.surname,
        email: data.email,
        user_name: data.user_name,
        password: data.password,
    }
    return axios.post(API_URL + `/user`, { ...params }, { headers: authHeader() })
}

export const _analyticsGuncelle = (data, id) => {
    return axios.put(API_URL + `/user/${id}`, { ...data }, { headers: authHeader() })
}