import React,{useState,useEffect} from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import service from './services'

const rows = [
  { id: 1, col1: 'Hello', col2: 'World' },
  { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
  { id: 3, col1: 'Material-UI', col2: 'is Amazing' },
];

const columns = [
  { field: 'id', headerName: 'ID', minWidth: 150 },
  { field: 'name', headerName: 'Name', minWidth: 150 },
  { field: 'entitytypeId', headerName: 'Category Id', minWidth: 150 },
];

export default function App() {
  const [data, setData] = useState(false);
  const [column, setColumn] = useState(false);
  useEffect(() => {
    service.sort()
      .then(list => {
        console.log(list)
        setData(list.data.data)
      })
  }, [])
  return (
    <div style={{ height: '100vh' , width: '100%' }}>
      {data&&<DataGrid rows={data} columns={columns} />}
    </div>
  );
}