import React from 'react';
import PropTypes from 'prop-types';
import List from './list'
import { _analyticslistesi, _analyticsDetay, _analyticsGuncelle, _analyticsKaydet, _analyticsSil } from './services'
import { Route, Switch } from 'react-router-dom';
import AddEdit from './AddEditV3'
import { Grid, Box } from '@mui/material'
import { AccountTree } from '@mui/icons-material';
import Datagrid from './datagrid'
const Analytics = function (props) {

    return <Switch>
        <Route exact path={`${props.path}`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={12} md={3} xl={3}>
                    <List
                        baslik={"Ürünler"}
                        path={props.path}
                    />
                </Grid>
                <Grid item sm={12} md={9} xl={9}>
                    <Box p={5}>
                        <p>
                            Bu bölümde sisteme varlık tanımlaması yapılır.
                            yani sisteminizde tanımlı olan varlık türlerinden bir tanesini seçerek tanımlama yapabilirsiniz.Örneğin hotel varlık türünü seçip hotel eklemesi yapabilirsiniz.
                        </p>
                        <Datagrid />
                    </Box>
                </Grid>
            </Grid>
        </Route>
        <Route path={`${props.path}/add`}>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >

                <AddEdit />

            </Grid>
        </Route>
        <Route path={`${props.path}/edit/:id`}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >

                <AddEdit />
            </Grid>
        </Route>
    </Switch >
}
Analytics.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/entity',
        component: Analytics
    },
    name: 'Ürünler',
    icon: <AccountTree fontSize="small" />,
    hideMenu: false,
    manage: false,
    listCategory: 2
}