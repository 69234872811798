import styled from 'styled-components'
export const TeeTimeSC = styled.div`
display:flex;
/* padding:50px 120px; */
box-sizing:border-box;
background-attachment: fixed;
background-size:cover;
background-repeat:no-repeat;
flex-direction: column;
justify-content:flex-start;
form{
    display:flex;
    flex-direction:column;
}
@media screen and (max-width: 1000px) {
    padding:50px 50px;
  }
}
.sahaekle{
    display:flex;
    button{
    border-radius:3px;
    border:none;
    margin:0px auto;
    padding:10px;
    background:black;
    color:white;
}}

`
export const Baslik = styled.p`
width:100%;
box-sizing:border-box;
font-weight:600;
font-size:2em;
text-align:center;
color:white;
`
export const AllWrap = styled.div`
/* flex-basis:100%; */
display:flex;
flex-direction:row;
justify-content:center;

`
export const Wrapper = styled.div`
flex-basis:100%;
box-sizing:border-box;
color:white;
display:flex;
justify-content:space-between;
flex-direction:row;
margin-bottom:20px;
    &:last-child{
        /* justify-content:flex-end; */
    }
    @media screen and (max-width: 1000px) {
        width:none !important;
        flex-basis:100%;
        flex-direction:column;
  }
`
export const Splitter = styled.div`
    flex-basis:49%;
    flex-direction:row;
    display:flex;
    @media screen and (max-width: 1000px) {
        flex-basis:100%;
  }
    label{
        font-size:1em;
        flex-basis:100%;
        display:flex;
        flex-direction:column;
        flex-wrap:wrap;
        box-sizing:border-box;
        span{
            /* flex-basis:100%; */
            font-size:1.2em;
            font-weight:bold;
            color:black;
        }
        input{
            margin-top:10px;
            /* flex-basis:100%; */
            background-color:#eff6f3;
            border:none;
            border-radius:3px;
            padding:10px;
            box-sizing:border-box;
            outline:1px;
            flex-basis:100%;
        }
        input[type=text], textarea {
            margin-top:10px;
            /* flex-basis:100%; */
            background-color:#eff6f3;
            border:none;
            border-radius:3px;
            padding:10px;
            box-sizing:border-box;
            outline:1px;
        }
        select{
            margin-top:10px;
            /* flex-basis:20%; */
            background-color:#eff6f3;
            border:none;
            border-radius:3px;
            padding:10px;
            box-sizing:border-box;
            outline:1px;
            /* padding-block-start: 14px; */
        }
        input[type="datetime-local"]{
            padding-block-start: 5px;
            /* flex-basis:100%; */
            box-sizing:border-box;
        }
        &:last-child{
            flex-basis:20%;
            padding-left:15px;
            box-sizing:border-box;
            @media screen and (max-width: 1000px) {
        flex-basis:100%;
  }
        }
        &:first-child{
            flex-basis:100%;
            padding-left:0px;
        }
    }
`
export const ButtonSC = styled.button`
    /* margin:0px auto; */
    border:none;
    border-radius:3px;
    text-transform:uppercase;
    font-size:16px;
    font-weight:600;
    background-color:#009900;
    color:white;
    padding:10px 30px;
    transition:300ms;
    &:hover{
        transition:300ms;
        background-color:black;
    }&:focus{
        border:none;
        outline:none;
    }
`
export const Input = styled.input.attrs({ 
    type: 'submit',
    value: 'Submit'
  })`
    border:none;
    border-radius:3px;
    text-transform:uppercase;
    font-size:16px;
    font-weight:600;
    background-color:#009900;
    color:white;
    padding:10px 30px;
    transition:300ms;
    &:hover{
        transition:300ms;
        background-color:black;
    }&:focus{
        border:none;
        outline:none;
    }
  `