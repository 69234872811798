import React from 'react';
import { GoVersions } from "react-icons/go";
function Version() {
    return (
        <div className="tasiyici">
            <div className="sag">
                <div className="inputs">
                    <div className="editorName">
                        <div className="baslik"><h2>Sürüm Bilgisi</h2></div>
                        <ul>
                            <li>Sürüm bilgileri buradan takip edilecektir.</li>
                            <li>Güncellemeler hakkında bilgi burdan paylaşılacaktır.</li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>

    )
}

export default {
    routeProps: {
        path: '/version',
        component: Version,
    },
    name: 'Version',
    icon:<GoVersions  fontSize="small"/>,
    headerLocale:true,
    hideMenu:true,
};