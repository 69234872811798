import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
export const _images = (id) => {
    return axios.get(API_URL + `/image/gallery/${id}`, { headers: authHeader() })
}
export const _silResim = (id) => {
    return axios.delete(API_URL + `/image/${id}`, { headers: authHeader() })
}
export const _gallerylistesi = () => {
    return axios.get(API_URL + '/gallery', { headers: authHeader() })
}
export const _hotellistesi = () => {
    return axios.get(API_URL + '/hotel', { headers: authHeader() })
}
export const _sahalistesi = () => {
    return axios.get(API_URL + '/saha', { headers: authHeader() })
}
export const _scorecardDetay = (id) => {
    return axios.get(API_URL + `/scorecard/${id}`, { headers: authHeader() })
}
export const _scorecardSil = (id) => {
    return axios.delete(API_URL + `/scorecard/${id}`, { headers: authHeader() })
}
export const _galleryKaydet = (data) => {
    const params = {
        name: data.name,
        checked: data.checked,
        outid: data.outid
    }
    return axios.post(API_URL + `/gallery`, { ...params }, { headers: authHeader() })
}
export const _upload = (data, cb) => {
    return axios.post(API_URL + `/uploads`, data, {
        headers: authHeader(), onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            cb(percentCompleted)
        }
    })
}
export const _paketdenSahaSil = (id) => {
    return axios.delete(API_URL + `/saha_and_paket/${id}`, { headers: authHeader() })
}
export const _paketdenPriceSil = (id) => {
    return axios.delete(API_URL + `/prices/${id}`, { headers: authHeader() })
}
export const _paketdenIcerikSil = (id) => {
    return axios.delete(API_URL + `/icerik/${id}`, { headers: authHeader() })
}
export const _scorecardColumnGuncelle = (data, id) => {
    return axios.put(API_URL + `/cardcolumn/${id}`, { ...data }, { headers: authHeader() })
}
export const _scorecardColumnKaydet = (data) => {
    const params = {
        name: data.name,
        color: data.color,
        text_color: data.text_color,
        sira_no: data.sira_no,
        scorecard_id: data.scorecard_id,
    }
    return axios.post(API_URL + `/cardcolumn`, { ...params }, { headers: authHeader() })
}
export const _scorecardColumnSil = (id) => {
    return axios.delete(API_URL + `/cardcolumn/${id}`, { headers: authHeader() })
}
export const _scorecardRowGuncelle = (data, id) => {
    return axios.put(API_URL + `/row/${id}`, { ...data }, { headers: authHeader() })
}
export const _scorecardRowKaydet = (data) => {
    const params = {
        uid: data.uid,
        value: data.value,
        sira_no: data.sira_no,
        set_bold: data.set_bold,
        cardcolumn_id: data.cardcolumn_id,
    }
    return axios.post(API_URL + `/row`, { ...params }, { headers: authHeader() })
}
export const _scorecardKaydet = (data) => {
    const params = {
        name: data.name,
        saha_id: data.saha_id,
        columnNames: data.columnNames
    }
    return axios.post(API_URL + `/scorecard`, { ...params }, { headers: authHeader() })
}