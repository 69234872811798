import React, { createContext, useReducer } from 'react'
import { node } from 'prop-types'

// load reducers
import userReducer from '../reducer/userReducer'

// craete initial payload
const user = process.env.NODE_ENV === "development" ? true:false

// create context
export const UserContext = createContext(user);

// create Store
function UserStore({ children }) {
  const [userState, userDispatch] = useReducer(userReducer, user);
  const value = { userState, userDispatch };
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

UserStore.defaultProps = {
  children: null
}

UserStore.propTypes = {
  children: node
}

export default UserStore;