import React, { Component } from 'react';
import Modal from "../../components/Modal";
import Lister from '../../components/List/'
import { IoIosAdd, IoMdRefreshCircle, IoMdSave,IoIosMail } from "react-icons/io";

class Mail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
            secim: false,
            dataBaslik: false,
            liste: false
        }
        this._contentUpdate = this._contentUpdate.bind(this);
        this._contentUpdateSliderItem = this._contentUpdateSliderItem.bind(this);
        this._contentUpdateSliderItemClear = this._contentUpdateSliderItemClear.bind(this);
        this._handleSec = this._handleSec.bind(this);
        this._clickEvent = this._clickEvent.bind(this);
        this._contentSave = this._contentSave.bind(this)
        this.__handleSecBaslik = this._handleSecBaslik.bind(this);

        this._clickDel = this._clickDel.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showModalAndClear = this.showModalAndClear.bind(this);

        this.getir = this.getir.bind(this);
    }
    showModal(e) {
        this.setState({
            show: !this.state.show
        });
    };
    showModalAndClear(e) {
        this.setState({
            secim: false,
            show: !this.state.show
        });
    };
    getir() {
        fetch(this.props.apiUrl + '/mailler')
            .then(res => {
                let routelist = res.json();
                console.log(routelist);
                return routelist;
            })
            .then(list => {
                console.log(list);
                this.setState({ dataBaslik: list.results })
            })
    }
    componentDidMount() {
        fetch(this.props.apiUrl + '/mailler')
            .then(res => {
                let routelist = res.json();
                console.log(routelist);
                return routelist;
            })
            .then(list => {
                console.log(list);
                this.setState({ dataBaslik: list.results })
            })

    }
    _handleChange(e, x) {
        let secimProp = { ...this.state.dataBaslik }
        // let alan = `name${e.target.name}`;

        secimProp[x][`${e.target.name}`] = e.target.value;
        this.forceUpdate()
        // this.setState({ data: this.state.data[0]secimProp })
    }
    _contentUpdate(i) {
        let self = this;
        // console.log("tıkladım")
        // console.log(this.state.secim)
        const { id, en, fr, de, ca } = this.state.dataBaslik[i];

        fetch(this.props.apiUrl + '/howitsguncelle', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ treatment_id: id, treatment: { en, fr, de, ca } })
        })
            .then(response => response.json())
            .then(function (data) {
                self.setState({ data })
                if (!data.error) {
                    self._listeGuncelle();
                }
            });

    }
    _contentUpdateSliderItem() {
        let self = this;
        // console.log("tıkladım")
        // console.log(this.state.secim)
        const { secim } = this.state;

        fetch(this.props.apiUrl + '/mailler', {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: secim.id,
                data: {
                    ...secim
                }
            })
        })

            .then(function (data) {
                // self.setState({ data })

                if (!data.error) {
                    self.getir();
                }
            });

    }
    _contentUpdateSliderItemClear() {
        console.log("clear")
        this.setState({ secim: false })


    }

    _handleSec(e) {
        let secimProp = { ...this.state.secim }
        let alan = `${e.target.name}`;
        console.log(alan)
        console.log(e.target.value)
        secimProp[alan] = e.target.value;
        this.setState({ secim: secimProp })
    }
    _handleSecBaslik(e, id) {
        console.log(e.target.name)
        console.log(id)
        let secimProp = { ...this.state.secim }
        let alan = `${e.target.name}`;
        secimProp.id = id;
        secimProp[alan] = e.target.value;
        // this.forceUpdate()
        this.setState({ secim: secimProp })
    }
    _clickEvent(e) {
        let self = this;
        console.log("cli")
        fetch(this.props.apiUrl + `/mailler/${e.id}`).then(response => response.json())
            .then((gelen) => {
                self.setState({
                    secim: gelen.data
                })
                this.showModal(e);
            });
    }
    _contentSave() {
        let self = this;
        let { secim } = self.state;
        console.log(self)

        // console.log(html)
        fetch(this.props.apiUrl + '/mailler', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    ...secim
                }
            })
        })
            .then(response => response.json())
            .then(function (data) {
                // self.setState({ data })
                if (!data.error) {
                    self.getir();
                }
            });


    }
    _clickDel(x) {
        console.log(x)
        fetch(this.props.apiUrl + `/mailler/${x}`, {

            crossDomain: true,
            xhrFields: {
                withCredentials: false,
            },
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
           
        })
            .then(response => this.getir())
        // axios.delete(
        //   this.props.apiUrl+'treatment',
        //   {headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //   },

        //   data:{
        //     body: JSON.stringify({ treatment_id: x.id })
        //   }}
        // ).then(response => this._listeGuncelle());
    }
    render() {
        const {  secim, dataBaslik, } = this.state;
        return (
            <div className="tasiyici">
                <Modal onClose={this.showModal} show={this.state.show}>
                    <div className="inputs">
                        <div className="editorName">
                            <input type="textbox" placeholder="Sağlayıcı Adı Örn:Hotmail" name="name" value={secim.name || ""} onChange={(e) => this._handleSec(e)} />
                            <input type="textbox" placeholder="Account Örn:info@cariagolf.com" name="account" value={secim.account || ""} onChange={(e) => this._handleSec(e)} />
                            <input type="textbox" placeholder="Şifre" name="password" value={secim.password || ""} onChange={(e) => this._handleSec(e)} />
                            <input type="textbox" placeholder="Gelen Posta Sunucusu" name="pop3" value={secim.pop3 || ""} onChange={(e) => this._handleSec(e)} />
                            <input type="textbox" placeholder="Giden Posta Sunucusu" name="smtp" value={secim.smtp || ""} onChange={(e) => this._handleSec(e)} />
                            <input type="textbox" placeholder="Gelen Port" name="pop_port" value={secim.pop_port || ""} onChange={(e) => this._handleSec(e)} />
                            <input type="textbox" placeholder="Giden Port" name="smtp_port" value={secim.smtp_port || ""} onChange={(e) => this._handleSec(e)} />
                            <select value={secim.varsayilan} defaultValue="0" name="varsayilan" onChange={(e) => this._handleSec(e)}>
                                <option value={1}>Evet</option>
                                <option value={0}>Hayır</option>
                            </select>
                        </div>
                        <div className="editorName">
                            <button disabled={(secim && !secim.id) ? false : true} onClick={this._contentSave}><IoMdSave />Kaydet</button>
                            <button disabled={secim.id ? false : true} onClick={this._contentUpdateSliderItem}><IoMdRefreshCircle />Güncelle</button>
                            <button disabled={secim ? false : true} onClick={this._contentUpdateSliderItemClear}> <IoIosAdd />Yeni Oluştur</button>
                        </div>
                    </div>
                </Modal>
                <div className="sol">
                    <div className="liste">
                    {dataBaslik ? <Lister
                            filter={""}
                            listeBaslik={"Mail Adresleri"}
                            dataBaslik={dataBaslik}
                            showModalAndClear={this.showModalAndClear}
                            _clickDel={this._clickDel}
                            _clickEvent={this._clickEvent}
                            visual={"name"}
                        /> : "Yükleniyor."}
                       
                    </div>
                </div>

            </div>

        )
    }
}

export default {
    routeProps: {
        path: '/mail',
        component: Mail,
    },
    name: 'Mail',
    icon: <IoIosMail fontSize="small"/>,
    hideMenu:true,
};