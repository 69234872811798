import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd, IoMdRefreshCircle, IoMdSave } from "react-icons/io";
import { FiGitPullRequest } from "react-icons/fi";
import { toast } from 'react-toastify';
import Modal from "../../components/Modal";
import List from './list'
import { Tasiyici, FormWrapper } from './styles.js'
import Sahalar from './sahalar'
import FInput from '../../components/floatigLabelInput'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { _talepGuncelle,_talepKaydet,_talepSil, _talepDetay, _taleplistesi, _hotellistesi, _sahalistesi, _paketlistesi } from './services'
const Talep = function (props) {
    const [secim, setSecim] = useState(false);
    const [hotellist, setHotelList] = useState(false);
    const [sahalist, setSahaList] = useState(false);
    const [paketlist, setPaketList] = useState(false);
    const [dataBaslik, setDataBaslik] = useState(false);
    const [filterdata, setFilterData] = useState(false);
    const [show, setShow] = useState(false);
    const [sahatalep, setSahaTalep] = useState(false);
    //TODO: saha ekleme güncelleme kısmınıda ekle frontendde olduğu gibi
    useEffect(() => {
        _taleplistesi()
            .then(list => {
                console.log(list)
                setDataBaslik(list.data.data)
                setFilterData(list.data.data)
            }).then(() => {
                _hotellistesi().then(hotels => {
                    setHotelList(hotels.data.data)
                })
            }).then(() => {
                _sahalistesi().then(saha => {
                    setSahaList(saha.data.data)
                })
            })
            .then(() => {
                _paketlistesi().then(paket => {
                    setPaketList(paket.data.data)
                })
            })
    }, [])
    function showModal(e) {
        setShow(!show)
    };
    function showModalAndClear(e) {
        setShow(!show)
        setSecim(false)
    };
    function getir() {
        _taleplistesi()
            .then(list => {
                console.log(list);
                setDataBaslik(list.data.data)
                setFilterData(list.data.data)
            })
    }
    function _contentUpdateSliderItem() {

        const { id } = secim;

        let courseFlat = Object.keys(sahatalep).map((key) => sahatalep[key]);
        let data = {
            talep: {
                ...secim,
            },
            courses: courseFlat,
            mailAt: false
        }
        _talepGuncelle({
            data: {
                ...data
            }
        },id)
            .then(function (data) {
                if (!data.error) {
                    toast.success('Güncelleme başarılı.')
                    getir();
                } else {
                    toast.error('Güncelleme yapılamadı.')
                }
            });

    }
    function _contentUpdateSliderItemClear() {
        setSecim(false)
    }
    function _handleSec(e) {
        let secimProp = { ...secim }
        console.log(e)
        let alan = `${e.target.name}`;


        secimProp[alan] = e.target.value;
        if (alan === "ticketchecked")
            secimProp[alan] = e.target.checked;
        console.log(e.target)
        setSecim(secimProp)
    }
    function dateChanger(e, name) {
        setSecim({ ...secim, [name]: e })
    }
    function _clickEvent(e) {
        // fetch(props.apiUrl + `/talep/${e.id}`).then(response => response.json())
        _talepDetay(e.id)
            .then((gelen) => {
                console.log(gelen)
                setSecim(gelen.data.data)
                setSahaTalep(gelen.data.sahatalep)
                showModal(e);
            });
    }
    function _contentSave() {
        let courseFlat = Object.keys(sahatalep).map((key) => sahatalep[key]);
        let data = {
            talep: {
                ...secim,
            },
            courses: courseFlat,
            mailAt: false
        }
        _talepKaydet({
            data: {
                ...data
            }
        })
            .then(function (data) {
                if (!data.data.error) {
                    toast.success('Kaydetme başarılı.')
                    getir();
                } else {
                    toast.error('Kaydetme yapılamadı.')
                }
            });


    }
    function _clickDel(x) {
        _talepSil(x)
            .then(function (data) {
                if (!data.data.error) {
                    toast.success('Silme başarılı.')
                    getir();
                } else {
                    toast.error('Silme yapılamadı.')
                }
            });
    }
    function filterlist(x) {
        let newArray = dataBaslik.filter(d => d.name.indexOf(x.target.value) !== -1);
        setFilterData(newArray)
    }
    return <Tasiyici>
        <Modal baslik={"Talep Ekle / Düzenle"} onClose={showModal} show={show}>
            <FormWrapper>
                <FInput
                    type="textbox"
                    placeholder="İsim"
                    name="name"
                    value={secim.name || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Soyisim"
                    name="surname"
                    value={secim.surname || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Telefon"
                    name="phone"
                    value={secim.phone || ""}
                    change={_handleSec}
                />
                <FInput
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    value={secim.email || ""}
                    change={_handleSec}
                />
                <FInput
                    type="select"
                    placeholder="Paket"
                    name="packet"
                    value={secim.packet || ""}
                    list={paketlist}
                    change={_handleSec}
                />

                <FInput
                    type="textbox"
                    placeholder="Ülke"
                    name="country"
                    value={secim.country || ""}
                    change={_handleSec}
                />
                <FInput
                    type="date"
                    placeholder="Gidiş Tarih"
                    name="arrivaldate"
                    value={secim.arrivaldate}
                    change={dateChanger}
                />
                <FInput
                    type="date"
                    placeholder="Dönüş Tarih"
                    name="departuredate"
                    value={secim.departuredate}
                    change={dateChanger}
                />
                <FInput
                    type="textbox"
                    placeholder="Golf Round"
                    name="golfround"
                    value={secim.golfround || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Golfer"
                    name="golfer"
                    value={secim.golfer || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Non Golfer"
                    name="nongolfer"
                    value={secim.nongolfer || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Children"
                    name="children"
                    value={secim.children || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Children Ages"
                    name="childrenages"
                    value={secim.childrenages || ""}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Note"
                    name="note"
                    value={secim.note || ""}
                    change={_handleSec}
                />
                <FInput
                    type="checkbox"
                    placeholder="Uçak Bileti istiyormu"
                    name="ticketchecked"
                    value={secim.ticketchecked ? true : false}
                    change={_handleSec}
                />
                <FInput
                    type="textbox"
                    placeholder="Airport"
                    name="airport"
                    disabled={secim.ticketchecked ? false : true}
                    value={secim.airport || ""}
                    change={_handleSec}
                />
                <FInput
                    type="select"
                    placeholder="Hotel"
                    name="hotel_id"
                    value={secim.hotel_id || ""}
                    list={hotellist}
                    change={_handleSec}
                />
                <Sahalar
                    apiUrl={props.apiUrl}
                    talepId={secim.id}
                    data={sahatalep}
                    sahalist={sahalist}
                />
            </FormWrapper>
            <div className="inputs">
                <div className="editorName">
                    <button className="buttons" disabled={(secim && !secim.id) ? false : true} onClick={() => _contentSave()}><IoMdSave />Kaydet</button>
                    <button className="buttons" disabled={secim.id ? false : true} onClick={() => _contentUpdateSliderItem()}><IoMdRefreshCircle />Güncelle</button>
                    <button className="buttons" disabled={secim ? false : true} onClick={() => _contentUpdateSliderItemClear()}> <IoIosAdd />Yeni Oluştur</button>
                </div>
            </div>
        </Modal>
        <div className="sol">
            <List
                baslik={"Talepler"}
                ekle={showModalAndClear}
                sil={_clickDel}
                edit={_clickEvent}
                data={filterdata}
                change={filterlist}
            />
        </div>
    </Tasiyici>
}
Talep.propTypes = {
    apiUrl: PropTypes.string.isRequired
};

export default {
    routeProps: {
        path: '/talep',
        component: Talep
    },
    name: 'Talepler',
    icon: <ImportExportIcon  fontSize="small"/>,
    hideMenu: false,
    listCategory:2,
}