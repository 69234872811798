import axios from "axios";
import { UrlContext } from '../../stores/url'
import authHeader from '../../utils/authHeader'
const API_URL = UrlContext._currentValue;
let routeName = "entity"
const service = {
    sort: async () => {
        return await axios.get(API_URL + `/${routeName}`, { headers: authHeader() })
    },
    detail:async (id) => {
        return await axios.get(API_URL + `/${routeName}/${id}`, { headers: authHeader() })
    },
    delete:async (id) => {
        return await axios.delete(API_URL + `/${routeName}/${id}`, { headers: authHeader() })
    },
    save:async (data) => {
        // const params = {
        //     name: data.name,
        //     trackedvalue: data.trackedvalue
        // }
        return await axios.post(API_URL + `/${routeName}`, { ...data }, { headers: authHeader() })
    },
    update:async (data, id) => {
        return await axios.put(API_URL + `/${routeName}/${id}`, { ...data }, { headers: authHeader() })
    },
    sort_entity_type:async () => {
        return await axios.get(API_URL + `/entitytype`, { headers: authHeader() })
    },
    languageList:async () => {
        return await axios.get(API_URL + '/language', { headers: authHeader() })
    },
    attributeList:async (id) => {
        return await axios.get(API_URL + `/attribute/attributegroup/${id}`, { headers: authHeader() })
    }
}
export default service
