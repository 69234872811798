import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
export default function (props) {
    return <div className="inputs">
        <div className="editorName">
            <label>{props.baslik}</label>
            <span title="Sayfa'nın ismi buradan belirlenir.Bütün linklerde buraya girilen değer görülür."><FaQuestionCircle /></span>
            <input type="text" required value={props.secim.nameen || ""} name="en" placeholder="e.g: Estetik Operasyonlar İngilizce" onChange={(e) => props.change(e)} />
            <input type="text" required value={props.secim.namefr || ""} name="fr" placeholder="e.g: Estetik Operasyonlar Fransızca" onChange={(e) => props.change(e)} />
            <input type="text" required value={props.secim.namede || ""} name="de" placeholder="e.g: Estetik Operasyonlar Almanca" onChange={(e) => props.change(e)} />
            <input type="text" required value={props.secim.nametr || ""} name="tr" placeholder="e.g: Estetik Operasyonlar Türkçe" onChange={(e) => props.change(e)} />
            <input type="text" required value={props.secim.nameru || ""} name="ru" placeholder="e.g: Estetik Operasyonlar Rusça" onChange={(e) => props.change(e)} />
            <input type="text" required value={props.secim.namecz || ""} name="cz" placeholder="e.g: Estetik Operasyonlar çekçe" onChange={(e) => props.change(e)} />
        </div></div>
}