import React, { useState, useEffect, Fragment } from 'react';
import { TeeTimeSC, Baslik, AllWrap, Wrapper, Splitter, ButtonSC } from './style'
import { _paketdenPriceSil } from '../services'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FloatInput } from '../../../components/floatigLabelInput/styles'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
export default (props) => {
    console.log(props.values)
    const [isActive, setIsActive] = useState(props.value ? true : false);
    function _fallFocus() {
        if (props.value !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }
    function sil(x) {
        _paketdenPriceSil(x)
            .then(function (data) {
                if (!data.data.error) {
                    // toast.success('Paket Silindi')
                    // _listeGuncelle();
                    // _yeniSayfa();
                } else {
                    // toast.error('Paket silme başarısız.')
                }
            });
    }
    return <TeeTimeSC>
        {/* {sahasCreator ? sahasCreator(props.art).map(x => x) : false} */}
        {props.values ? props.values.map((x, index) => {
            return <AllWrap key={index} index={index}>
                <Wrapper>
                    <Splitter>
                        <label>
                            <span>Start Date #{index + 1}</span>
                            {/* <input name="start_date" value={x.start_date || ""} type="date" onChange={(e) => props.change(e, index)} /> */}
                            <DatePicker
                                dateFormat="dd.MM.yyyy"
                                name="start_date"
                                selected={x.start_date ? moment(x.start_date).toDate() : false}
                                locale="en-GB"
                                autoComplete="off"
                                onFocus={() => setIsActive(true)}
                                onBlur={() => _fallFocus()}
                                onChange={(e) => props.change("start_date", e, index)}
                                customInput={<FloatInput />}
                            // onChange={(e) => props.dateChanger(e, "arrivaldate")}
                            />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>End Date #{index + 1}</span>
                            {/* <input name="end_date" type="date" value={x.end_date || ""} onChange={(e) => props.change(e, index)} /> */}
                            <DatePicker
                                dateFormat="dd.MM.yyyy"
                                name="end_date"
                                selected={x.end_date ? moment(x.end_date).toDate() : false}
                                locale="en-GB"
                                autoComplete="off"
                                onFocus={() => setIsActive(true)}
                                onBlur={() => _fallFocus()}
                                onChange={(e) => props.change("end_date", e, index)}
                                customInput={<FloatInput />}
                            />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>Price Single #{index + 1}</span>
                            <input name="price" type="textbox" value={x.price || ""} onChange={(e) => props.change("price",e.target.value, index)} />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>Currency #{index + 1}</span>
                            <input name="currency" type="textbox" value={x.currency || ""} onChange={(e) => props.change("currency",e.target.value, index)} />
                        </label>
                    </Splitter>
                    <Splitter>
                        <label>
                            <span>İşlemler</span>
                            <button onClick={(e) => {
                                if (x.id) {
                                    sil(x.id)
                                    props.delete(e, index)
                                } else {
                                    props.delete(e, index)
                                }
                            }}>Sil</button>
                        </label>
                    </Splitter>
                </Wrapper>
            </AllWrap>
        }) : false}
    </TeeTimeSC>
}