import React, { useState, useEffect } from 'react';
import { MdNotificationsActive } from "react-icons/md";
import usePushNotifications from "./PushPage/usePushNotifications";
import { Wrapper, Inputs, List } from './styles'
import { _subscriptionlistesi} from './services'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// const Loading = ({ loading }) => (loading ? <div className="app-loader">Please wait, we are loading something...</div> : null);
const Error = ({ error }) =>
  error ? (
    <section className="app-error">
      <h2>{error.name}</h2>
      <p>Error message : {error.message}</p>
      <p>Error code : {error.code}</p>
    </section>
  ) : null;

function PushNotificationDemo(props) {
  const {
    // userConsent,
    // pushNotificationSupported,
    // userSubscription,
    // onClickAskUserPermission,
    // onClickSusbribeToPushNotification,
    // onClickSendSubscriptionToPushServer,
    // pushServerSubscriptionId,
    // onClickSendNotification,
    error,
    // loading,
    hepsineBildir
  } = usePushNotifications();

  // const isConsentGranted = userConsent === "granted";
  const [title, setTitle] = useState(false);
  const [text, setText] = useState(false);
  const [image, setImage] = useState(false);
  const [tag, setTag] = useState(false);
  const [url, setUrl] = useState(false);
  const [subscriptions, setSubscriptions] = useState(false);
  const [checklist, setChecklist] = useState([]);
  // const [checked, setChecked] = useState(false);
  // function _getAll() {
  //   fetch(props.apiUrl + '/subscription')
  //     .then(res => {
  //       let routelist = res.json();
        
  //       return routelist;
  //     })
  //     .then(list => {
        
  //       setSubscriptions(list.data)
  //     })
  // }
  useEffect(() => {
    _subscriptionlistesi()
      .then(list => {
        
        setSubscriptions(list.data.data)
      })
    // _getAll();
  }, [props.apiUrl])
  function _checker(x) {
    if (checklist.indexOf(x) === -1) {
      setChecklist(checklist => [...checklist, x])
      
    } else {
      // const index = checklist.indexOf(x);
      // const removeArr = checklist.splice(index, 1);
      const arrRm = checklist.filter(function (y) {
        return y !== x
      })
      setChecklist(arrRm)
      
    }
  }
  function _checkAll() {
    if (checklist.length > 0) {
      setChecklist([])
      // setChecked(false)
    } else {
      setChecklist(subscriptions)
      // setChecked(true)
    }

  }

  return (
    <Wrapper>
      {/* <List>
        {checklist ? <ul>{checklist.map((x, i) => {
          return <li key={i}>
            {x.id} - {x.auth}
          </li>
        })}</ul> : false}
      </List> */}
      <List>
        <button onClick={() => _checkAll()}>
          {checklist.length > 0 ? "Seçimi Temizle" : "Tümünü Seç"}
        </button>
        {subscriptions ? <ul>{subscriptions.map((x, i) => {
          return <li key={i}>
            <input type="checkbox" checked={checklist.indexOf(x) === -1?false:true} onChange={(e) => _checker(x)} />
            {x.id} - {x.auth}
          </li>
        })}</ul> : false}
      </List>
      <Inputs>
        <label>
          Başlık
        <input type="textbox" onChange={(e) => setTitle(e.target.value)} />
        </label>
        <label>
          Açıklama
        <input type="textbox" onChange={(e) => setText(e.target.value)} />
        </label>
        <label>
          Minik Resim Url
        <input type="textbox" onChange={(e) => setImage(e.target.value)} />
        </label>
        <label>
          Etiket
        <input type="textbox" onChange={(e) => setTag(e.target.value)} />
        </label>
        <label>
          Tıklayınca açılacak link
        <input type="textbox" onChange={(e) => setUrl(e.target.value)} />
        </label>
        <button onClick={() => hepsineBildir({ title, text, image, tag, url,checklist })}>
          Hepsine Gönder
      </button>

      </Inputs>
      <Error error={error} />


    </Wrapper>
  );
}
export default {
  routeProps: {
    path: '/push',
    component: PushNotificationDemo,
  },
  name: 'Mobil Bildirimler',
  icon: <NotificationsActiveIcon  fontSize="small"/>,
  hideMenu:false,
  manage:true
};