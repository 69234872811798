import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom'
import { TextField, Switch, FormGroup, FormLabel, FormControlLabel } from '@mui/material';
// import { FormWrapperSc } from './styles'
import { Button, Box } from '@mui/material';
import service from './services'
import { useHistory } from "react-router-dom";
function AddEdit() {
    // const { id } = match.params;
    let history = useHistory();
    let { id } = useParams();
    const isAddMode = !id;
    const [user, setUser] = useState({});
    const { control, register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
    function onSubmit(data) {
        return isAddMode
            ? createUser(data)
            : updateUser(id, data);
    }

    function createUser(data) {
        return service.save(data)
            .then(() => {
                toast.success('Ekleme başarılı.')
                history.push('.');
            })
            .catch((err) => {
                toast.error('Ekleme başarısız.')
                console.log(err)
            });
    }

    function updateUser(id, data) {
        return service.update(data, id)
            .then(() => {
                toast.success('Güncelleme başarılı.')
                history.push('..');
            })
            .catch((err) => {
                toast.error('Güncelleme başarısız.')
                console.log(err)
            });
    }
    useEffect(() => {
        if (!isAddMode) {
            service.detail(id).then(user => {
                const fields = ['name', 'trackedvalue'];
                fields.forEach(field => setValue(field, user.data.data[field]));
                setUser(user);
            });
        }
    }, []);
    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormLabel>Platform {!isAddMode ? "Düzenle" : "Ekle"}</FormLabel>
                <FormGroup column>
                    <Controller
                        render={({ field }) => <TextField {...field} label="İsim" margin="normal" variant="outlined" />}
                        control={control}
                        name="name"
                        defaultValue=""
                    />
                    <Controller
                        render={({ field }) => <TextField {...field} label="Uygulama Kodu" margin="normal" variant="outlined" />}
                        control={control}
                        name="trackedvalue"
                        defaultValue=""
                    />
                </FormGroup>


                <FormGroup row >
                    <Box m={1} ml={0}>
                        <Button variant="contained" color="primary" type="submit">
                            Kaydet
                        </Button>
                    </Box>
                    <Box m={1}>
                        <Button component={Link} variant="contained" color="secondary" to={isAddMode ? '.' : '..'}>
                            İptal
                        </Button>
                    </Box>
                </FormGroup>
                {errors.code && <span>This field is required</span>}
            </form >
        </Box>
    );
}
export { AddEdit }