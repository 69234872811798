import { Divider, Typography, Container, Item, Grid, Button, TextField, Box, FormLabel, FormGroup, Select, MenuItem, FormControl, FormControlLabel, InputLabel } from "@mui/material";
import { Field, FieldArray, FieldProps, Form, Formik, getIn, useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { generate } from "shortid";
import * as yup from "yup";
import { useHistory, Link, useParams } from "react-router-dom";
import service from './services'
const validationSchema = yup.object().shape({
    entitylabeltranslations: yup.array().of(
        yup.object().shape({
            name: yup.string().max(1000),
            value: yup.string(200)
        })
    ),
    // attr: yup.array().of(
    //     yup.object().shape({
    //         name: yup.string().max(1000),
    //         value: yup.string(200)
    //     })
    // )
});



const AddEdit = () => {
    let history = useHistory();
    let { id } = useParams();
    const isAddMode = !id;
    const [entityTypeList, setEntityTypeList] = useState([]);
    const [languagelist, setLanguagelist] = useState(false);
    const [attributelist, setAttributeList] = useState(false);
    function get_entity_type_list() {
        service.entitytypeList().then(user => {
            console.log(user)
            // setValue("entity_type_id", user.data.data)
            setEntityTypeList(user.data.data);
        });
    }
    function get_language_list() {
        service.languageList().then(incoming => {
            setLanguagelist(incoming.data.data)
            formik.setFieldValue('table_label_translations', incoming.data.data)
            formik.setFieldValue('columns[0].column_label_translations', incoming.data.data)
        });
    }
    function get_entity_detail(pk) {
        service.detail(pk).then(async (user) => {
            console.log(user)
            let gelen = user.data.data;
            formik.setFieldValue('name', gelen.name)
            // formik.setFieldValue('attributetypeId', gelen.attributetypeId)

            const etlt = languagelist.map((l, i) => {
                let dat = { ...l }
                const za = gelen.table_label_translations.forEach((g) => {

                    if (l.id == g.languageId) {
                        dat.value = g.value
                        // dat.entityId = g.entityId
                        dat.labeltranslationId = g.id
                        // dat = {...dat,...g }
                    }

                    return dat
                });
                return dat
            })
            console.log(etlt)
            formik.setFieldValue('table_label_translations', etlt)
            // let attrlist = await get_attribute_list(gelen.entitytypeId).then((data) => data)
            // const atls = attrlist.map((a, i) => {
            //     let dat = { ...a }
            //     const zi = gelen.entityattributevalues.forEach((e) => {
            //         if (a.attributeId == e.attributeId && a.langId == e.languageId) {
            //             dat.value = e.value;
            //             dat.entityId = e.entityId
            //         }
            //         return dat
            //     })
            //     return dat
            // })
            // console.log({ 'gelenattr': gelen.entityattributevalues })
            // console.log({ 'attrlist': attrlist })
            // console.log({ 'atls': atls })
            // formik.setFieldValue('attr', atls)
        });
    }

    useEffect(() => {
        get_language_list()
        // get_entity_type_list()
    }, [])
    useEffect(() => {
        if (id && languagelist) {
            get_entity_detail(id)
            console.log('neden1')
        } else {
            console.log('neden')
        }
    }, [languagelist, id]);
    function entityTypeChange(e, setFieldValue) {
        // get_attribute_list(e)
        // setFieldValue('entitytypeId', e)
    }
    // useEffect(() => {
    //     setFieldValue('entitylabeltranslations', languagelist)
    // }, [
    //     languagelist
    // ])
    function createUser(data) {
        return service.save(data)
            .then(() => {
                toast.success('Ekleme başarılı.')
                history.push('.');
            })
            .catch((err) => {
                toast.error('Ekleme başarısız.')
            });
    }

    function updateUser(id, data) {
        return service.update(data, id)
            .then(() => {
                toast.success('Güncelleme başarılı.')
                history.push('..');
            })
            .catch((err) => {
                toast.error('Güncelleme başarısız.')
            });
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            table_label_translations: [{ id: "", value: '', labeltranslationId: '' }],
            columns: [
                {
                    name: '',
                    sort: '',
                    // value: ''
                    column_label_translations: [{ id: "", value: '', labeltranslationId: '' }],
                },
            ],
        },
        onSubmit: values => {
            console.log(values)
            alert(JSON.stringify(values, null, 2));
            if (isAddMode) { createUser(values) } else {
                updateUser(id, values);
            }
        },
        // validationSchema: validationSchema,
        validateOnChange: false
    });
    return (
        <Container maxWidth="lg">

            <FormikProvider value={formik}>
                <Box component="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <FormGroup column>
                                    <FormLabel>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            Tablo {!isAddMode ? "Düzenle" : "Ekle"}
                                        </Typography>
                                    </FormLabel>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <FormGroup column>
                                    <TextField label="Tablo Adı" id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />
                                </FormGroup>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={6}>


                        </Grid>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <FormGroup column>
                                    <FormLabel>
                                        <Typography variant="h6" component="div" gutterBottom>
                                            Yabancı Dil Karşılıkları {!isAddMode ? "Düzenle" : "Ekle"}
                                        </Typography>
                                    </FormLabel>
                                </FormGroup>
                            </Grid>
                            <FieldArray name="table_label_translations"
                                render={(arrayHelpers) => (
                                    formik.values.table_label_translations.map((lang, index) => (
                                        <Grid item xs={12} md={6}>
                                            <FormGroup column>
                                                <TextField
                                                    label={`${lang.name && lang.name} Karşılığı`}
                                                    key={index}
                                                    name={`table_label_translations[${index}].value`}
                                                    value={formik.values.table_label_translations[index].value || ''}
                                                    onChange={formik.handleChange}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    ))
                                )}
                            />
                        </Grid>
                        <Grid container spacing={1} xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <FormGroup column>
                                    <FormLabel>
                                        <Typography variant="h6" component="div" gutterBottom>
                                            Tabloya Sütun {!isAddMode ? "Düzenle" : "Ekle"}
                                        </Typography>
                                    </FormLabel>
                                </FormGroup>
                            </Grid>
                            {console.log({ 'values': formik.values })}
                            <FieldArray name="columns">
                                {fieldArrayProps => {
                                    console.log('fieldArrayProps', fieldArrayProps)
                                    const { push, remove, form } = fieldArrayProps
                                    const { values } = form
                                    const { columns } = values
                                    return columns.map((x, i) => (
                                        <Grid container spacing={1} xs={12} md={12} key={i}>
                                            <Grid item xs={12} md={6} >
                                                <FormGroup column>
                                                    <TextField
                                                        label="Sütun Adı"
                                                        key={i}
                                                        name={`columns[${i}].name`}
                                                        // value={formik.values.friends[i].value || ''}
                                                        onChange={formik.handleChange}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            {x.column_label_translations.map((l, s) => {
                                                return <Grid item xs={12} md={6} key={s}>
                                                    <FormGroup column>
                                                        <TextField
                                                            // label={`column_label_translations[${i}].name`}
                                                            // label={`${lang.name && lang.name} Karşılığı`}
                                                            label={`${columns[i].column_label_translations[s].name} Adı Karşılığı`}
                                                            name={`columns[${i}].column_label_translations[${s}].value`}
                                                            // value={formik.values.friends[i].value || ''}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            })}
                                            {/* <Grid item xs={12} md={6} >
                                                <FormGroup column>
                                                    <TextField
                                                        label={`column_label_translations[${i}].name`}
                                                        // label={`${lang.name && lang.name} Karşılığı`}
                                                        key={i}
                                                        name={`friends[${i}].column_label_translations[${i}].value`}
                                                        // value={formik.values.friends[i].value || ''}
                                                        onChange={formik.handleChange}
                                                    />
                                                </FormGroup>
                                            </Grid> */}
                                            <Grid item xs={12} md={6} >
                                                <FormGroup column>
                                                    <TextField
                                                        label="Sıra No"
                                                        // label={`${lang.name && lang.name} Karşılığı`}
                                                        key={i}
                                                        name={`columns[${i}].sort`}
                                                        // value={formik.values.friends[i].value || ''}
                                                        onChange={formik.handleChange}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12} md={12} >
                                                <FormGroup column>
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => push({ name: '', sort: '', column_label_translations: [...languagelist], })}
                                                    >
                                                        Add Friend
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => remove(i)}
                                                    >
                                                        Remove Friend
                                                    </button>
                                                </FormGroup>

                                            </Grid>
                                        </Grid>
                                    ))



                                }}
                                {/* render={(arrayHelpers) => (
                                    formik.values.friends.map((lang, index) => (
                                        <Grid item xs={12} md={6}>
                                            <FormGroup column>
                                                <TextField
                                                    label={`${lang.name && lang.name} Karşılığı`}
                                                    key={index}
                                                    name={`table_label_translations[${index}].value`}
                                                    value={formik.values.friends[index].value || ''}
                                                    onChange={formik.handleChange}
                                                />
                                                <button
                                                    type="button"
                                                    className="secondary"
                                                    onClick={() => arrayHelpers.push({ name: '', email: '',value:'' })}
                                                >
                                                    Add Friend
                                                </button>
                                            </FormGroup>
                                        </Grid>
                                    )) */}
                                {/* )} */}
                            </FieldArray>

                        </Grid>
                        <Grid item xs={12} md={6}>

                        </Grid>


                        <Grid item xs={12} md={12}>
                            <FormGroup column>
                                <Button type="submit" variant="contained">submit</Button>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>
            </FormikProvider >
            {/* </Formik> */}
            < pre > {JSON.stringify(formik.values, null, 2)}</pre >
            <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
        </Container >
    );
};

export default AddEdit;