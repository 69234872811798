// import React from 'react';
import jwtDecode from 'jwt-decode';
import { loginUser, logoutUser } from '../actions/userActions'
export default (cb) => {

    let token = localStorage.getItem('user')
    if (token) {
        let tokenExpiration = jwtDecode(token).exp;
        let dateNow = new Date();

        if (tokenExpiration < dateNow.getTime() / 1000) {
            console.log("log out")
            return cb(logoutUser())
        } else {
            console.log("log in")
            return cb(loginUser())
        }
    } else {
        console.log("log out")
        return cb(logoutUser())
    }

}
