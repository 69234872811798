import React from 'react';
import {Table} from './styles'
import TableHeader from './tableHeader'
import TableBody from './tableBody'
export default (props)=>{
    return  <Table>
        {props.children}
        <TableHeader {...props}/>
        <TableBody  {...props}/>
    </Table>
}